import styled from "styled-components";

import { CopyToClipboard } from "react-copy-to-clipboard";

import cancelIcon from "assets/svg/cancel.svg";
import adminCreationSuccessIcon from "assets/svg/admin-creation-success.svg";
import copyIcon from "assets/svg/copy.svg";
import { useState } from "react";
import { Toast, ToastStatus } from "components/toast.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  background-color: #fff;
  border-radius: 20px;
  padding: 3rem 2.5rem;

  header {
    width: 100%;
    margin-bottom: 2.5rem;
    button {
      background: transparent;
      border: none;
      display: block;
      margin-left: auto;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  div.wrapper {
    text-align: center;
    & > img {
      width: 80%;
      max-width: 223px;
      display: block;
      margin: 0 auto 1rem;
    }
    h1 {
      font-weight: 400;
      font-size: 20px;
      color: #1c344f;
      margin-bottom: 0.8rem;
    }
    h2 {
      font-weight: 400;
      font-size: 16px;
      color: #748595;
      margin-bottom: 2rem;
    }

    div.password {
      padding: 1rem 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(232, 235, 237, 0.3);
      border: 1px solid #cbd1d7;
      border-radius: 8px;
      max-width: 410px;
      margin: 0 auto;
      margin-bottom: 2.5rem;

      p {
        font-weight: 400;
        font-size: 12px;
        color: #cbd1d7;
      }
      button {
        border: none;
        background: transparent;
      }
    }
  }
`;

export const AdminCreationSuccess = ({
  closeOverlay,
  password,
  setHasAddedAdmin,
}) => {
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);

  return (
    <Container>
      {isPasswordCopied && (
        <Toast
          text={"Password copied successfully"}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}
      <header>
        <button
          onClick={() => {
            closeOverlay();
            setHasAddedAdmin(false);
          }}
        >
          <img src={cancelIcon} alt="" />
        </button>
      </header>
      <div className="wrapper">
        <img src={adminCreationSuccessIcon} alt="" />
        <h1>Admin Account Created Successfully</h1>
        <h2>Share password with Admin</h2>
        <div className="password">
          <p>{password}</p>
          <CopyToClipboard
            onCopy={() => {
              setIsPasswordCopied(true);
              setTimeout(() => {
                setIsPasswordCopied(false);
              }, 3500);
            }}
            text={password}
          >
            <button>
              <img src={copyIcon} alt="" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Container>
  );
};
