import styled from "styled-components";
import { useEffect, useState } from "react";
import { axiosInstance } from "utils/api";
import { getToken } from "utils/firebase";
import DropdownMenu from "components/dropdown-menu.component";

const TableRow = styled.tr`
  div.wrapper {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      margin-right: 1rem;
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
    div.side {
      display: flex;
      flex-direction: column;
      em {
        font-style: normal;
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #1c344f;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:last-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
        }
      }
    }
  }
  td.points {
    em {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1c344f;
      display: inline-block;
      margin-right: 8px;
      width: 70px;
      padding: 1rem;
      border-radius: 60px;
      background: #fef4e6;
      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
  }
  td.status {
    em {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      border-radius: 60px;
      padding: 4px 12px;
      &.online {
        color: #6955eb;
        background: #f7f6fe;
      }
      &.offline {
        color: #db6b5d;
        background: #fdf8f7;
      }
    }
  }
  td.action {
    button {
      display: flex;
      align-items: center;
      &.disable {
        background: #c44931;
      }
      &.enable {
        background: #3a9428;
      }

      border-radius: 6px;
      border: none;
      padding: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
  }
`;

export const AdminRow = ({
  name,
  emailAddress,
  phoneNo,
  status,
  enabledStatus,
  setError,
  setSuccess,
  customSetTimeout,
  editAdmin,
  deleteAdmin,
  id,
}) => {
  // const [isLoading, setLoading] = useState(false);

  const changeAdminStatus = async (newStatus) => {
    setError("");
    // setLoading(true);
    const user = {
      id,
      enabled: newStatus,
    };

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.patch("/admin/enabled-status", user, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // setLoading(false);

      if (response.status === 200) {
        editAdmin(id, newStatus);
        setSuccess(`The Admin is now ${newStatus ? "enabled" : "disabled"}`);
      }
    } catch (e) {
      // setLoading(false);
      setError(e.response.data.message);
      customSetTimeout(() => {
        setError("");
      });

      console.log(e);
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const actions = [];

    if (enabledStatus) {
      actions.push("Disable");
    } else {
      actions.push("Enable");
    }

    actions.push("Remove");

    setOptions(actions);
  }, [enabledStatus]);

  const handleOptionSelected = (option) => {
    if (option != "Remove") {
      if (option === "Disable") {
        changeAdminStatus(false);
      } else {
        changeAdminStatus(true);
      }
    } else {
      deleteAdmin(id, "admin");
    }
  };

  return (
    <TableRow>
      <td>
        <div className="wrapper">
          <div className="side">
            <em>{name}</em>
            <em>{phoneNo}</em>
          </div>
        </div>
      </td>
      <td>{emailAddress}</td>

      <td className="status">
        <em className={`${status ? "online" : "offline"}`}>
          {status ? "Online" : "Offline"}
        </em>
      </td>

      <td className="action">
        <DropdownMenu options={options} onSelected={handleOptionSelected} />
      </td>
    </TableRow>
  );
};
