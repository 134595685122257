import styled from "styled-components";
import requestImage1 from "assets/png/requestImage1.png";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f4f5f6;

  padding: 2rem;
  background-color: ${(props) => (props.active ? "#F8F9FA" : "#ffffff")};

  img {
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 25px;
  }

  div.details {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    span {
      display: block;

      &:nth-child(1) {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        color: #1c344f;
        font-weight: 500;
      }

      &:nth-child(2) {
        color: #748595;
      }
    }
  }

  div.time {
    margin-left: auto;
    color: #313840;
  }
`;

const ActiveChatItem = ({
  room,
  latestMessage,
  active = false,
  setActiveRoom,
}) => {
  const getTime = () => {
    if (!latestMessage) return "";

    const date = new Date(latestMessage.sent_at._seconds * 1000);

    let hour = date.getHours();
    if (hour < 10) hour = `0${hour}`;

    let min = date.getMinutes();
    if (min < 10) min = `0${min}`;

    return `${hour}:${min}`;
  };

  const handleClick = () => {
    setActiveRoom(room);
  };

  return (
    <Container active={active} onClick={handleClick}>
      <img src={room.user.avatar || requestImage1} />
      <div className="details">
        <span>
          {room.user.firstname} {room.user.lastname}
        </span>
        <span>{latestMessage?.message}</span>
      </div>

      {latestMessage && <div className="time">{getTime()}</div>}
    </Container>
  );
};

export default ActiveChatItem;
