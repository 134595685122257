import { useEffect, useState } from "react";
import styled from "styled-components";

export const ToastStatus = {
  success: "success",
  failed: "failed",
};

const Container = styled.div`
  position: absolute;

  background: ${({ toastStatus }) =>
    toastStatus === ToastStatus.success ? "#3a9428" : "#fc2c03"};
  border-radius: 6px;
  width: ${({ toastWidth }) => toastWidth || "90%"};
  max-width: 650px;
  padding: 1.5rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 1s ease-in-out;
  ${({ login }) =>
    login
      ? `
    top: 180px;
  `
      : ""}
  &.closing {
    opacity: 0;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }
`;

export const Toast = ({ text, toastStatus, timeout, width, login }) => {
  const [active, setActive] = useState(true);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setClosing(true);
    }, timeout);
    setTimeout(() => {
      setActive(false);
    }, timeout + 1000);
  }, [timeout]);

  return (
    active && (
      <Container
        toastWidth={width}
        className={closing ? "closing" : ""}
        toastStatus={toastStatus}
        login={login}
      >
        <p>{text}</p>
      </Container>
    )
  );
};
