import { MiniLoader } from "components/loader.component";
import { useState } from "react";
import styled from "styled-components";
import socket from "utils/socket";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #1c344f;
    margin-bottom: 2rem;
  }

  div.divider {
    width: 80%;
    height: 1px;

    background: #e8ebed;
    margin-bottom: 2rem;
  }

  div.actions {
    display: flex;
    flex-direction: row;
    align-items;
    justify-content: center;

    button {
        outline: none;
        border: none;
        height: 5.5rem;
        width: 15rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        border-radius: 8px;

        &:nth-child(1) {
            margin-right: 1.6rem;
        }

        &.delete {
            background-color: #ffffff;
            color: #55B653;
        }

        &.accept {
            background-color: #55B653;
            color: #ffffff;
        }
    }
  }

  @media screen and (min-width: 900px) {
    div.divider {
      width: 70%;
    }

    div.actions {

      button {
        height: 5.5rem;
        width: 22.9rem;
      } 
    }

  }
`;

const RequestActionComponent = ({
  req,
  deleteRequest,
  setActiveRequest,
  setError,
  goBack,
}) => {
  const [accepting, setAccepting] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const acceptRequest = async () => {
    setAccepting(true);
    if (socket.connected) {
      socket.emit(
        "chat_request",
        { id: req.id, status: "accepted" },
        ({ error }) => {
          if (error) {
            console.error(error);
            setError(error);
            setTimeout(() => {
              setError("");
            }, 4300);
            setAccepting(false);
          } else {
            deleteRequest({ id: req.id });
            setActiveRequest(null);
            setAccepting(false);
          }
        }
      );
    } else {
      setAccepting(false);
      console.log("No Socket Connection");
    }
  };

  const declineRequest = async () => {
    setDeleting(true);
    if (socket.connected) {
      socket.emit(
        "chat_request",
        { id: req.id, status: "declined" },
        ({ error }) => {
          if (error) {
            setDeleting(false);
            console.error(error);
            setError(error);

            setTimeout(() => {
              setError("");
            }, 4300);
          } else {
            deleteRequest({ id: req.id });
            setActiveRequest(null);
            setDeleting(false);
          }
        }
      );
    }

    setDeleting(false);
  };

  return (
    <Container>
      <h5>
        Request from {req.user.firstname} {req.user.lastname}
      </h5>

      <div className="divider" />

      <div className="actions">
        <button
          className="delete"
          disabled={accepting || deleting}
          onClick={declineRequest}
        >
          {deleting ? <MiniLoader color="#55B653" /> : "Delete Request"}
        </button>

        <button
          className="accept"
          onClick={acceptRequest}
          disabled={accepting || deleting}
        >
          {accepting ? <MiniLoader color="#ffffff" /> : "Accept Request"}
        </button>
      </div>
    </Container>
  );
};

export default RequestActionComponent;
