import styled from "styled-components";

import cancelIcon from "assets/svg/cancel.svg";
import { useState } from "react";
import { axiosInstance } from "utils/api";
import { MiniLoader } from "components/loader.component";
import { getToken } from "utils/firebase";
import { Toast, ToastStatus } from "components/toast.component";
import InputComponent from "components/input.component";
import PhoneInputComponent from "components/phone-input.component";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import MultiSelect from "components/multi-select.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .indicator {
    margin-bottom: 3rem;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    border-bottom: 1px solid #ebedf0;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      color: #1c344f;
    }

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  form {
    padding: 2rem 2.5rem;
    flex: 1;
    overflow-y: auto;

    .name {
      display: grid;
      gap: 20px;
    }

    label {
      display: block;
      margin-bottom: 3rem;
      span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 1.2rem;
      }
    }

    div.products {
      margin-bottom: 3rem;
      & > span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 1.2rem;
      }
      label {
        display: flex;
        align-items: center;
        span {
          margin-bottom: 0%;
        }
        input {
          width: unset;
          margin-right: 5px;
        }
      }
    }

    button {
      display: block;
      margin-left: auto;
      margin-bottom: 2rem;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #55b653;
      border-radius: 8px;
      border: none;
      padding: 1.5rem;
      height: 56px;
      min-width: 159px;
      /* width: 105px; */
    }
  }

  @media screen and (min-width: 600px) {
    form {
      .name {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

export const AccessLevel = {
  Super: "Super",
  Normal: "Normal",
};

export const NewClient = ({
  closeOverlay,
  addClient,
  isEditMode,
  updateClient,
  editData,
}) => {
  const [firstName, setFirstName] = useState(editData?.firstname || "");
  const [lastName, setLastName] = useState(editData?.lastname || "");
  const [email, setEmail] = useState(editData?.email || "");
  const [dialCode, setDialCode] = useState(editData?.dialCode || "");
  const [phoneNumber, setPhoneNumber] = useState(editData?.phonenumber || "");

  const { products } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [selectedProducts, setSelectedProducts] = useState(
    editData?.products || []
  );
  const updateSelectedProducts = (list) => setSelectedProducts(list);

  const submitClient = async (e) => {
    e.preventDefault();

    setError("");
    setSuccess("");
    setLoading(true);

    const client = {
      firstname: firstName,
      lastname: lastName,
      email,
      products: selectedProducts.map((item) => item.id),
      phonenumber: phoneNumber,
      dialCode,
    };

    let accessToken = await getToken();
    if (!accessToken) {
      setLoading(false);
      return;
    }

    try {
      let response = await axiosInstance.post("/admin/client", client, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);

      if (response.status === 201) {
        setSuccess("Client created successfully");

        addClient(response.data.data);
        setTimeout(() => {
          setSuccess("");
          closeOverlay();
        }, 4000);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  const getUpdatePayload = () => {
    if (!editData) return {};

    const payload = {};

    if (firstName !== editData.firstname) {
      payload.firstname = firstName;
    }

    if (lastName !== editData.lastname) {
      payload.lastname = lastName;
    }

    if (dialCode !== editData.dialCode) {
      payload.dialCode = dialCode;
    }

    if (phoneNumber !== editData.phonenumber) {
      payload.phonenumber = phoneNumber;
    }

    if (email !== editData.email) {
      payload.email = email;
    }

    payload.products = selectedProducts.map((item) => item.id);

    return payload;
  };

  const updateClientData = async (e) => {
    e.preventDefault();
    if (!editData) return;

    setError("");
    setSuccess("");
    setLoading(true);

    const client = { id: editData.id, ...getUpdatePayload() };

    let accessToken = await getToken();
    if (!accessToken) {
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.patch("/admin/client", client, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        setSuccess("Client updated successfully");

        updateClient(client);

        setTimeout(() => {
          setSuccess("");
          closeOverlay();
        }, 4000);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}

      <header>
        <h1>{isEditMode ? "Edit Client" : "Create New Client"}</h1>
        <button onClick={closeOverlay}>
          <img src={cancelIcon} alt="" />
        </button>
      </header>

      <form
        id="newClientForm"
        onSubmit={isEditMode ? updateClientData : submitClient}
      >
        <div className="name">
          <label htmlFor="firstName">
            <span>First Name</span>
            <InputComponent
              id="firstName"
              placeholder="Enter your first name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </label>

          <label htmlFor="lastName">
            <span>Last Name</span>
            <InputComponent
              id="lastName"
              placeholder="Enter your last name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
            />
          </label>
        </div>
        <label htmlFor="phone">
          <span>Phone Number</span>
          <PhoneInputComponent
            id="phone"
            placeholder="Enter your phone number"
            onChange={(phone, country) => {
              setDialCode(country.dialCode);
              setPhoneNumber(phone.slice(country.dialCode.length));
            }}
            value={dialCode + phoneNumber}
            required
          />
        </label>
        <label htmlFor="email">
          <span>Email</span>
          <InputComponent
            id="email"
            type="email"
            placeholder="name@domain.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </label>

        <div className="products">
          <span>Recyclable Product</span>
          <MultiSelect
            options={products}
            selectedValues={selectedProducts}
            onSelect={updateSelectedProducts}
            onRemove={updateSelectedProducts}
            displayValue="product_name"
            placeholder="Select products"
          />
        </div>

        <button form="newClientForm" disabled={loading}>
          {loading ? <MiniLoader /> : isEditMode ? "Save" : "Create"}
        </button>
      </form>
    </Container>
  );
};
