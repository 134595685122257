import styled from "styled-components";

import cancelIcon from "assets/svg/cancel.svg";
import { ProductClass } from "components/product-card.component";
import { DragDrop } from "components/drag-drop.component";
import { useState } from "react";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";
import { Toast, ToastStatus } from "components/toast.component";
import { MiniLoader } from "components/loader.component";
import InputComponent from "components/input.component";
import SelectComponent from "components/select-component";
import crownIcon from "assets/svg/crown.svg";
import LinearIndicator from "components/linear-indicator.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .indicator {
    margin-bottom: 3rem;
  }

  header {
    padding: 2rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      color: #1c344f;
    }

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  & > form {
    flex: 1;
    overflow-y: auto;
    padding: 2rem 2.5rem;

    label.dragdrop {
      width: 100%;
      display: block;

      & > span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 3rem;
      }
    }

    label.mainForm {
      display: block;
      margin-bottom: 3rem;
      span {
        font-size: 16px;
        color: #1c344f;
        display: block;
        margin-bottom: 12px;
      }
    }

    div.button-container {
      margin-left: auto;
      margin-bottom: 2rem;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        display: block;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        background: #55b653;
        border-radius: 8px;
        border: none;
        padding: 1.5rem;
        height: 56px;
        min-width: 159px;
        margin-right: 1.6rem;
        /* width: 105px; */

        &.back {
          color: #aeb8c1;
          border: 1px solid #cbd1d7;
          background-color: #f8f9fa;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const UnitContainer = styled.div`
  font-size: 16px;
  color: #47525c;
`;

export const NewProduct = ({
  closeOverlay,
  addProduct,
  isEditMode,
  editProduct,
  editData,
}) => {
  const [name, setName] = useState(editData?.product_name || "");
  const [productClass, setProductClass] = useState(
    editData?.product_class || ProductClass.general
  );
  const [minQty, setMinQty] = useState(editData?.min_quantity || "");
  const [pointsPerUnit, setPointsPerUnit] = useState(
    editData?.points_per_unit || ""
  );
  const [imageUrl, setImageUrl] = useState(editData?.img || "");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [step, setStep] = useState(1);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  const submitProduct = async (e) => {
    e.preventDefault();

    if (step === 1) {
      nextStep();
      return;
    }

    setError("");
    setSuccess("");
    setLoading(true);

    const product = {
      product_name: name,
      points_per_unit: Number(pointsPerUnit),
      product_class: productClass,
      img: imageUrl,
      min_quantity: Number(minQty),
      isEmoji: false,
    };

    let accessToken = await getToken();
    if (!accessToken) return;
    try {
      let response = await axiosInstance.post("/admin/product", product, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      console.log(response);

      if (response.status === 201) {
        setSuccess("Product created successfully");
        addProduct(response.data.data);
        setTimeout(() => {
          setSuccess("");
          closeOverlay();
        }, 4000);
      }
    } catch (e) {
      setLoading(false);
      console.log(e.response);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  const getUpdatePayload = () => {
    if (!editData) return {};

    const payload = {};

    if (name !== editData.product_name) {
      payload.product_name = name;
    }

    if (productClass !== editData.product_class) {
      payload.product_class = productClass;
    }

    if (minQty !== editData.min_quantity) {
      payload.min_quantity = parseInt(minQty);
    }

    if (pointsPerUnit !== editData.points_per_unit) {
      payload.points_per_unit = parseInt(pointsPerUnit);
    }

    if (imageUrl !== editData.img) {
      payload.img = imageUrl;
    }

    return payload;
  };

  const updateProductData = async (e) => {
    e.preventDefault();

    if (step === 1) {
      nextStep();
      return;
    }

    setError("");
    setSuccess("");
    setLoading(true);

    const product = {
      product_id: editData.id,
      ...getUpdatePayload(),
      isEmoji: false,
    };

    let accessToken = await getToken();
    if (!accessToken) {
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.patch("/admin/product", product, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        setSuccess("Product updated successfully");

        editProduct(product);

        setTimeout(() => {
          setSuccess("");
          closeOverlay();
        }, 4000);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}

      <header>
        <h1>{isEditMode ? "Edit Product" : "Add New Product"}</h1>
        <button onClick={closeOverlay}>
          <img src={cancelIcon} alt="" />
        </button>
      </header>

      <div className="indicator">
        <LinearIndicator value={step / 4} />
      </div>

      <form
        id="productForm"
        onSubmit={isEditMode ? updateProductData : submitProduct}
      >
        {step === 1 && (
          <>
            <label className="mainForm" htmlFor="product-name">
              <span>Product Name</span>
              <InputComponent
                id="product-name"
                placeholder="Name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </label>

            <label className="mainForm" htmlFor="product-class">
              <span>Product Class</span>
              <SelectComponent
                id="product-class"
                value={productClass}
                onChange={(e) => setProductClass(e.target.value)}
                options={[ProductClass.general, ProductClass.specific]}
                required
              />
            </label>

            <label className="mainForm" htmlFor="min-quantity">
              <span>Minimum Quantity</span>
              <InputComponent
                id="min-quantity"
                type="number"
                placeholder="10"
                onChange={(e) => setMinQty(e.target.value)}
                value={minQty}
                suffixIcon={
                  <UnitContainer>
                    {productClass === ProductClass.specific ? "Units" : "Kg"}
                  </UnitContainer>
                }
                required
              />
            </label>

            <label className="mainForm" htmlFor="points">
              <span>Points</span>
              <InputComponent
                prefixIcon={<img src={crownIcon} />}
                id="points"
                placeholder="10"
                type="number"
                onChange={(e) => setPointsPerUnit(e.target.value)}
                value={pointsPerUnit}
                required
              />
            </label>
          </>
        )}

        {step === 2 && (
          <>
            <label className="dragdrop" htmlFor="file">
              <span>Upload Product Image</span>
              <DragDrop
                setUrl={setImageUrl}
                setSuccess={setSuccess}
                setError={setError}
                url={imageUrl}
              />
            </label>
          </>
        )}

        <div className="button-container">
          {step > 1 && (
            <button className="back" disabled={loading} onClick={prevStep}>
              Back
            </button>
          )}

          <button type="submit" form="productForm" disabled={loading}>
            {loading ? (
              <MiniLoader />
            ) : step === 1 ? (
              "Continue"
            ) : isEditMode ? (
              "Save"
            ) : (
              "Add product"
            )}
          </button>
        </div>
      </form>
    </Container>
  );
};
