import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;

  .loader {
    height: 100px;
    text-align: center;
    padding: 1em;
    display: inline-block;
    vertical-align: top;
  }

  /* Set the color of the icon */
  svg path,
  svg rect {
    fill: #4b7aa7;
  }
`;

const MiniContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* background-color: white; */

  /* .loader {
    height: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
  } */

  /* Set the color of the icon */
  svg path,
  svg rect {
    fill: ${({ inputColor }) => inputColor || "#fff"};
  }
`;

export const Loader = () => (
  <Container>
    <div className="loader loader--style3" title="2">
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100px"
        height="100px"
        viewBox="0 0 50 50"
        style={{ enableBackground: "new 0 0 50 50" }}
      >
        <path
          fill="#000"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </Container>
);

export const MiniLoader = ({ color, width = "14px" }) => (
  <MiniContainer inputColor={color}>
    <div className="loader loader--style3" title="2">
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        viewBox="0 0 50 50"
        style={{ enableBackground: "new 0 0 50 50" }}
      >
        <path
          fill="#000"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </MiniContainer>
);

export default Loader;
