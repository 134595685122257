import styled from "styled-components";
import ellipseDivideIcon from "assets/svg/ellipse-divide.svg";
import crownIcon from "assets/svg/crown.svg";
import DropdownMenu from "./dropdown-menu.component";

const options = ["Edit", "Remove"];

const Container = styled.div`
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dce2e6;

  div.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    align-self: stretch;
    div.top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      em {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #1c344f;
        display: inline-block;
        margin-right: 8px;
      }
      div.right {
        display: flex;
        align-items: center;
        em {
          font-size: 12px;
          margin-right: 0;
        }
        img {
          width: 4px;
          height: 4px;
          margin: 0 8px;
          display: inline-block;
        }
      }
    }
    div.bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div.left {
        display: flex;
        max-width: 245px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        width: 150px;
        em {
          font-style: normal;
          &:first-child {
            padding: 5px;
            border-radius: 60px;
            background: #fef4e6;
            font-size: 14px;
            width: 90px;
            margin-right: 10px;
            img {
              width: 15px;
              height: 15px;
              display: inline-block;
              margin-right: 8px;
              margin-left: 5px;
              position: relative;
              top: 1px;
            }
          }
          &:last-child {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            border-radius: 60px;
            padding: 4px 12px;
            &.general {
              color: #71bf5f;
              background: #ebf6e9;
            }
            &.specific {
              color: #6955eb;
              background: #f7f6fe;
            }
          }
        }
      }

      button {
        border: none;
        background: transparent;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
`;

export const ProductClass = {
  general: "General",
  specific: "Specific",
};

export const ProductCard = ({
  name,
  points,
  productId,
  productMinQty,
  productClass,
  openEditOverlay,
  openDeleteOverlay,
}) => {
  const handleOptionSelected = (option) => {
    if (option === "Edit") {
      openEditOverlay();
    } else {
      openDeleteOverlay(productId);
    }
  };

  return (
    <Container>
      <div className="wrapper">
        <div className="top">
          <em>{name}</em>
          <div className="right">
            <em>id: {productId}</em>
            <img src={ellipseDivideIcon} alt="" />
            <em>{productMinQty}</em>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <em>
              <img src={crownIcon} alt="" />
              {points}
            </em>
            <em className={`${productClass.toLowerCase()}`}>{productClass}</em>
          </div>

          <DropdownMenu options={options} onSelected={handleOptionSelected} />
        </div>
      </div>
    </Container>
  );
};
