import styled from "styled-components";
import { ClientTable } from "components/tables/client/client-table.component";
import { useContext, useState } from "react";
import { Overlay } from "components/overlay.component";
import { NewClient } from "components/overlays/new-client.overlay";
import { AppContext } from "context/app.provider";
import { useEffect } from "react";
import { Toast, ToastStatus } from "components/toast.component";
import UserDeleteConfirmation from "components/overlays/user-delete.overlay";
import ClientMobile from "components/tables/client/client-mobile";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.table-container {
    display: none;
  }

  div.container {
    margin-right: 2rem;
    margin-left: 2rem;
    height: 100%;
  }

  @media screen and (min-width: 1200px) {
    div.container {
      display: none;
    }
    div.table-container {
      display: block;
      margin-right: 2rem;
      margin-left: 2rem;
      height: 100%;
    }
  }
`;

export const ClientPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);

  const { products, clients, setClients } = useContext(AppContext);

  const addClient = (client) => {
    setClients([client, ...clients]);
  };

  const editClient = (client) => {
    const clientProducts = products.filter((item) =>
      client.products?.includes(item.id)
    );

    delete client.products;

    setClients((prev) =>
      prev.map((item) => {
        if (item.id === client.id) {
          return { ...item, ...client, products: clientProducts };
        }

        return item;
      })
    );
  };

  const openEditOverlay = (client) => {
    setEditData(client);
    setIsEditMode(true);
    setIsOverlayOpen(true);
  };

  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);

  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("admin");

  const showDeleteOverlay = (id, type) => {
    setUserId(id);
    setUserType(type);
    setIsDeleteOverlayOpen(true);
  };

  const resetOverlay = () => {
    setIsOverlayOpen(false);
    setIsEditMode(false);
    setEditData(null);

    setError("");
    setUserId(null);
    setUserType("admin");
    setIsDeleteOverlayOpen(false);
  };

  useEffect(() => {
    return resetOverlay;
  }, []);

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <Overlay
        closeOverlay={resetOverlay}
        isVisible={isOverlayOpen}
        Component={NewClient}
        extraProps={{
          addClient: addClient,
          isEditMode,
          editData,
          updateClient: editClient,
        }}
      />

      <Overlay
        closeOverlay={() => setIsDeleteOverlayOpen(false)}
        isVisible={isDeleteOverlayOpen}
        Component={UserDeleteConfirmation}
        extraProps={{ setError, userId, type: userType }}
      />

      <div className="container">
        <ClientMobile
          clients={clients}
          openOverlay={() => setIsOverlayOpen(true)}
          openEditOverlay={openEditOverlay}
          deleteClient={showDeleteOverlay}
        />
      </div>
      <div className="table-container">
        <ClientTable
          openOverlay={() => setIsOverlayOpen(true)}
          clients={clients}
          openEditOverlay={openEditOverlay}
          deleteClient={showDeleteOverlay}
        />
      </div>
    </Container>
  );
};
