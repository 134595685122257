import styled from "styled-components";

import horizontalDotsIcon from "assets/svg/horizontal-dots.svg";
import ellipseDivideIcon from "assets/svg/ellipse-divide.svg";
import DropdownMenu from "./dropdown-menu.component";

const Container = styled.div`
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dce2e6;
  button {
    border: none;
    background: transparent;
    img {
      width: 15px;
      height: 15px;
    }
  }
  div.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    align-self: stretch;
    div.top {
      margin-bottom: 2rem;
      em {
        &:first-child {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #1c344f;
          display: inline-block;
          margin-right: 2.8rem;
        }
        &:last-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          border-radius: 60px;
          padding: 4px 12px;
          &.online {
            color: #6955eb;
            background: #f7f6fe;
          }
          &.offline {
            color: #db6b5d;
            background: #fdf8f7;
          }
        }
      }
    }
    div.bottom {
      em {
        display: flex;
        align-items: center;
        max-width: 245px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        font-style: normal;
        span {
          font-weight: 400;
          font-size: 12px;
          color: #748595;
          &:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        img {
          width: 4px;
          height: 4px;
          margin: 0 8px;
          display: inline-block;
        }
      }
    }
  }
`;

export const ClientCard = ({
  name,
  productType,
  number,
  email,
  removeClient,
  openEditOverlay,
}) => {
  const handleOptionSelected = (option) => {
    if (option === "Remove") {
      // Remove Client
      removeClient();
    } else if (option === "Edit") {
      // Open edit overlay
      openEditOverlay();
    }
  };

  return (
    <Container>
      <div className="wrapper">
        <div className="top">
          <em>{name}</em>
          <em>{productType}</em>
        </div>
        <div className="bottom">
          <em>
            <span>{number}</span>
            <img src={ellipseDivideIcon} alt="" />
            <span>{email}</span>
          </em>
        </div>
      </div>

      <DropdownMenu
        options={["Edit", "Remove"]}
        onSelected={handleOptionSelected}
      />
    </Container>
  );
};
