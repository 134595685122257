import styled from "styled-components";
import { AdminTable } from "components/tables/admin/admin-table.component";
import { useContext, useState } from "react";
import { Overlay } from "components/overlay.component";
import { NewAdmin } from "components/overlays/new-admin.overlay";
import { AdminCreationSuccess } from "components/overlays/admin-creation-success.overlay";
import { AppContext } from "context/app.provider";
import UserDeleteConfirmation from "components/overlays/user-delete.overlay";
import { Toast, ToastStatus } from "components/toast.component";
import { useEffect } from "react";
import AdminMobile from "components/tables/admin/admin-mobile.component";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.table-container {
    display: none;
  }

  div.container {
    margin-right: 2rem;
    margin-left: 2rem;
    height: 100%;
  }

  @media screen and (min-width: 1200px) {
    div.container {
      display: none;
    }
    div.table-container {
      display: block;
      margin-right: 2rem;
      margin-left: 2rem;
      height: 100%;
    }
  }
`;

export const AdminPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const { admins, setAdmins } = useContext(AppContext);
  const [hasAddedAdmin, setHasAddedAdmin] = useState(false);
  const [newAdminPassword, setNewAdminPassword] = useState("");

  const editAdmin = (id, newStatus) => {
    setAdmins((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, enabled: newStatus };
        }

        return item;
      })
    );
  };

  const addAdmin = (admin) => {
    setAdmins([admin, ...admins]);
  };

  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);

  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("admin");

  const showDeleteOverlay = (id, type) => {
    setUserId(id);
    setUserType(type);
    setIsDeleteOverlayOpen(true);
  };

  const reset = () => {
    setError("");
    setUserId(null);
    setUserType("admin");
    setIsDeleteOverlayOpen(false);
  };

  useEffect(() => {
    return reset;
  }, []);

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <Overlay
        closeOverlay={() => setIsOverlayOpen(false)}
        isVisible={isOverlayOpen}
        Component={hasAddedAdmin ? AdminCreationSuccess : NewAdmin}
        extraProps={
          hasAddedAdmin
            ? {
                password: newAdminPassword,
                setHasAddedAdmin,
              }
            : {
                setHasAddedAdmin,
                setNewAdminPassword,
                addAdmin,
              }
        }
      />

      <Overlay
        closeOverlay={() => setIsDeleteOverlayOpen(false)}
        isVisible={isDeleteOverlayOpen}
        Component={UserDeleteConfirmation}
        extraProps={{ setError, userId, type: userType }}
      />

      <div className="container">
        <AdminMobile
          admins={admins}
          editAdmin={editAdmin}
          deleteAdmin={showDeleteOverlay}
          openOverlay={() => setIsOverlayOpen(true)}
        />
      </div>
      <div className="table-container">
        <AdminTable
          openOverlay={() => setIsOverlayOpen(true)}
          admins={admins}
          editAdmin={editAdmin}
          deleteAdmin={showDeleteOverlay}
        />
      </div>
    </Container>
  );
};
