import React from "react";
import { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
  font-size: 1rem;
  color: #1c344f;
  text-align: center;

  @media screen and (min-width: 900px) {
    font-size: 1.4rem;
  }
`;

function PaginationInfoComponent({ itemOffset, endOffset, size }) {
  const infoText = useMemo(() => {
    return `Showing ${itemOffset + 1} - ${endOffset} out of ${size}`;
  }, [itemOffset, size, endOffset]);

  return <Container>{infoText}</Container>;
}

export default PaginationInfoComponent;
