import styled from "styled-components";
import requestImage1 from "assets/png/requestImage1.png";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import { Message } from "./message.component";
import { useState } from "react";
import messageSend from "assets/svg/messageSend.svg";
import socket from "utils/socket";
import { useEffect } from "react";
import { MiniLoader } from "components/loader.component";
import back from "assets/svg/back_arrow.svg";

const Container = styled.div`
  height: 100%;
  // padding-right: 2rem;

  header {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e6ed;

    div {
      margin-left: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      img.back {
        margin-right: 1rem;
        cursor: pointer;
      }

      img.avatar {
        display: inline-block;
        height: 50px;
        width: 50px;
        border-radius: 25px;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c344f;

        &:nth-child(2) {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #748595;
        }
      }
    }

    button {
      outline: none;
      border: 0.5px solid #d54937;
      border-radius: 4px;
      width: 105px;
      height: 33px;
      background: #fdf8f7;
      font-weight: 500;
      font-size: 10.7636px;
      line-height: 13px;
      color: #d54937;
      margin-right: 2rem;
    }
  }

  div.chat {
    height: calc(100% - 180px);
    overflow-y: auto;
    width: 100%;
    text-align: center;
    & > em {
      width: 200px;
      font-style: normal;
      background: #f4f5f6;
      border-radius: 2.82993px;
      font-weight: 400;
      font-size: 12px;
      display: inline-block;
      margin: 1rem auto;
      color: #1c344f;
      padding: 0.5rem 1rem;
    }
  }

  form.input-container {
    height: 80px;

    div.input-wrapper {
      width: 95%;
      margin: 0 auto;
      background: #e8ebed;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.9rem 1.8rem;
      button {
        border: none;
        background: transparent;
        img {
          width: 2.5rem;
          display: inline-block;
          margin-right: 10px;
        }
      }
      input {
        display: inline-block;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        background: transparent;
        border: none;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

const ChatScreen = ({
  room,
  handleNewMessage,
  terminating,
  terminateActiveRoom,
  goBack,
}) => {
  const { currentUser } = useContext(AppContext);

  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    setSending(true);

    if (socket.connected) {
      socket.emit(
        "message",
        { message, room: room.room },
        ({ error, message }) => {
          setSending(false);

          if (error) {
            // console.log({ error});
          } else {
            // console.log(message);
            handleNewMessage(message);
            setMessage("");
          }
        }
      );
    } else {
      console.log({ error: "No socket connection", type: "error" });
    }
  };

  useEffect(() => {
    const chatBox = document.querySelector("div.chat");
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, []);

  const mediaQuery = window.matchMedia("(min-width: 900px)");
  const [showDesktopVersion, setShowDesktopVersion] = useState(
    mediaQuery.matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowDesktopVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  return (
    <Container>
      <header>
        <div>
          {!showDesktopVersion && (
            <img className="back" src={back} onClick={goBack} />
          )}

          <img className="avatar" src={room.user.avatar || requestImage1} />

          <div>
            <span>
              {room.user.firstname} {room.user.lastname}
            </span>

            <span>{room.user.email}</span>
          </div>
        </div>

        <button disabled={terminating} onClick={terminateActiveRoom}>
          {terminating ? <MiniLoader color="#d54937" /> : "End chat"}
        </button>
      </header>

      <div className="chat">
        <em>{new Date(room.created_at._seconds * 1000).toDateString()}</em>

        {room.messages instanceof Array &&
          room.messages.map((message) => {
            return (
              <Message
                key={message.id}
                left={currentUser.id !== message.sender.id}
                right={currentUser.id === message.sender.id}
                message={message}
              />
            );
          })}
      </div>

      <form id="chatForm" className="input-container" onSubmit={sendMessage}>
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Write a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit" form="chatForm" disabled={sending}>
            <img src={messageSend} alt="" />
          </button>
        </div>
      </form>
    </Container>
  );
};

export default ChatScreen;
