import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Toggler } from "./toggler.component";

import hamburgerIcon from "assets/svg/hamburger.svg";
import cancelIcon from "assets/svg/cancel.svg";
import logo from "assets/svg/new-logo.svg";
import notificationIcon from "assets/svg/notification.svg";
import { ROUTES } from "../utils/routes";
import { logOut } from "utils/firebase";
import { AppContext } from "context/app.provider";
import { useEffect } from "react";

const Container = styled.div`
  position: relative;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #f3f4f4;
  height: 80px;

  img {
    width: 2.2rem;
    height: 2.2rem;
  }
  button.hamburger {
    border: none;
    background: transparent;
  }
  div.name {
    display: flex;
    align-items: center;
    img {
      display: block;
      width: 9rem;
      margin-right: 0.8rem;
    }
    em {
      font-style: normal;
      font-size: 1.6rem;
    }
  }
`;

const Sidebar = styled.section`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  right: 100%;
  transition: 0.5s;
  z-index: 1;
  &.active {
    right: 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    list-style-type: none;
    li {
      padding: 2rem 0;
      a,
      button {
        border: none;
        background: transparent;
        font-size: 1.6rem;
        color: #748595;
        text-decoration: none;
        &.active {
          color: #224f21;
        }
      }
    }
  }
`;

export const Navbar = () => {
  const [isNotificationActive, setNotificationActive] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { currentUser } = useContext(AppContext);

  return (
    <Container>
      <Nav>
        <button
          className="hamburger"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={isSidebarOpen ? cancelIcon : hamburgerIcon} alt="" />
        </button>

        <div className="name">
          <img src={logo} alt="" />
        </div>

        {/* <img src={notificationIcon} alt="" /> */}
        <Toggler
          active={isNotificationActive}
          setActive={setNotificationActive}
        />
      </Nav>
      <Sidebar className={`${isSidebarOpen && "active"}`}>
        <ul onClick={() => setIsSidebarOpen(false)}>
          <li>
            <NavLink to={ROUTES.home}>Dashboard</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.user}>User List</NavLink>
          </li>
          {currentUser?.access_level?.toLowerCase() === "super" && (
            <li>
              <NavLink to={ROUTES.admin}>Admin List</NavLink>
            </li>
          )}
          <li>
            <NavLink to={ROUTES.client}>Client List</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.recycle}>Recycle Requests</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.products}>Products</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.liveChat}>Live Chat</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.settings}>Settings</NavLink>
          </li>
          <li>
            <button
              onClick={(e) => {
                e.preventDefault();
                logOut();
              }}
            >
              Logout
            </button>
          </li>
        </ul>
      </Sidebar>
    </Container>
  );
};
