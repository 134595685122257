import styled from "styled-components";

import ellipseDivideIcon from "assets/svg/ellipse-divide.svg";
import crownIcon from "assets/svg/crown.svg";
import { useEffect, useState } from "react";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";
import DropdownMenu from "./dropdown-menu.component";

const Container = styled.div`
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dce2e6;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: block;
    margin-right: 1.2rem;
  }
  div.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    align-self: stretch;
    div.top {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1.6rem;

      div.top-left {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        em {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #1c344f;
          display: inline-block;
          margin-right: 8px;
          &:nth-child(2) {
            padding: 5px;
            border-radius: 60px;
            background: #fef4e6;
            img {
              width: 15px;
              height: 15px;
              display: inline-block;
              margin-right: 8px;
              position: relative;
              top: 1px;
            }
          }
          &:last-child {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            border-radius: 60px;
            padding: 4px 12px;
            &.enabled {
              color: #71bf5f;
              background: #ebf6e9;
            }
            &.disabled {
              color: #db6b5d;
              background: #fdf8f7;
            }
          }
        }
      }
    }
    div.bottom {
      em {
        display: flex;
        align-items: center;
        max-width: 245px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        font-style: normal;
        span {
          font-weight: 400;
          font-size: 12px;
          color: #748595;
          &:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        img {
          width: 4px;
          height: 4px;
          margin: 0 8px;
          display: inline-block;
        }
      }
    }
  }
`;

export const UserCard = ({
  id,
  name,
  status,
  imageUrl,
  number,
  email,
  points,
  referral_points,
  editUser,
  setError,
  setSuccess,
  deleteUser,
  showPointsInfo,
  setPointsInfo,
}) => {
  const [isLoading, setLoading] = useState(false);

  const changeUserStatus = async (newStatus) => {
    setError("");
    setLoading(true);

    const user = {
      id,
      status: newStatus ? "enabled" : "disabled",
    };

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.patch("/admin/user", user, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);

      if (response.status === 200) {
        editUser(id, newStatus ? "enabled" : "disabled");
        setSuccess(`The User is now ${newStatus ? "enabled" : "disabled"}`);
        // customSetTimeout(() => {
        //   setSuccess("");
        // });
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const actions = [];

    if (status.toLowerCase() === "enabled") {
      actions.push("Disable");
    } else {
      actions.push("Enable");
    }

    actions.push("Remove");

    setOptions(actions);
  }, [status]);

  const handleOptionSelected = (option) => {
    if (isLoading) return;

    if (option != "Remove") {
      if (option === "Disable") {
        changeUserStatus(false);
      } else {
        changeUserStatus(true);
      }
    } else {
      deleteUser(id, "user");
    }
  };

  return (
    <Container>
      <img src={imageUrl} alt="User" />
      <div className="wrapper">
        <div className="top">
          <div className="top-left">
            <em>{name}</em>

            <em
              onClick={() => {
                setPointsInfo({
                  pickupPoints: points,
                  referralPoints: referral_points,
                });
                showPointsInfo();
              }}
            >
              <img src={crownIcon} alt="" />
              {points + referral_points}
            </em>

            <em
              className={`${
                status.toLowerCase() === "enabled" ? "enabled" : "disabled"
              }`}
            >
              {status.toLowerCase() === "enabled" ? "Enabled" : "Disabled"}
            </em>
          </div>

          <DropdownMenu options={options} onSelected={handleOptionSelected} />
        </div>

        <div className="bottom">
          <em>
            <span>{number}</span>
            <img src={ellipseDivideIcon} alt="" />
            <span>{email}</span>
          </em>
        </div>
      </div>
    </Container>
  );
};
