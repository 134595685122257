import { MiniLoader } from "components/loader.component";
import { AppContext } from "context/app.provider";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { axiosInstance } from "utils/api";
import { getToken } from "utils/firebase";
import deleteBin from "assets/svg/delete-bin.svg";

const Container = styled.div`
  width: 80%;
  max-width: 440px;
  background-color: #fff;
  border-radius: 20px;
  padding: 5rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  div.confirmationText {
    font-size: 1.8rem;
    color: #1c344f;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  div.removed {
    font-size: 2.4rem;
    color: #1c344f;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  div.message {
    color: #b3b7c6;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  div.actions {
    button {
      outline: none;
      border: none;
      border-radius: 3px;
      font-weight: 500;
      font-size: 15px;
      min-width: 104px;
      height: 36px;
    }

    button.positive {
      margin-right: 1.2rem;
      color: #748595;
      background: none;
    }

    button.negative {
      background: #fef4e6;
      border: 1px solid #fbb051;
      box-shadow: 0px 4px 14px rgba(255, 123, 1, 0.1);
      color: #fbb051;
    }
  }
`;

function UserDeleteConfirmation({
  closeOverlay,
  userId,
  type = "admin",
  setError,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { setAdmins, setUsers, setClients } = useContext(AppContext);

  const removeAdmin = (id) => {
    setAdmins((prev) => prev.filter((item) => item.id !== id));
  };

  const removeUser = (id) => {
    setUsers((prev) => prev.filter((item) => item.id !== id));
  };

  const deleteAdmin = async () => {
    if (!userId) return;

    setError("");
    setLoading(true);

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.delete(`/admin?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setLoading(false);

      if (response.status === 200) {
        removeAdmin(userId);
        setSuccess(true);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  const deleteUser = async () => {
    setError("");
    setLoading(true);

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.delete(
        `/admin/user?user_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        removeUser(userId);
        setSuccess(true);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  const deleteClient = async () => {
    setError("");
    setLoading(true);

    try {
      let accessToken = await getToken();
      if (!accessToken) return;

      let response = await axiosInstance.delete(
        `/admin/client?user_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        setClients((prev) => prev.filter((item) => item.id !== userId));
        setSuccess(true);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      setError(e.response?.data?.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  const onDelete = () => {
    if (type === "admin") {
      deleteAdmin();
    } else if (type === "user") {
      deleteUser();
    } else if (type === "client") {
      deleteClient();
    }
  };

  const reset = () => {
    setSuccess(false);
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <Container>
      {!success ? (
        <>
          <div className="confirmationText">
            Are you sure you want to remove {type?.toLowerCase()}?
          </div>

          <div className="actions">
            <button className="positive" onClick={onDelete}>
              {loading ? (
                <MiniLoader width="20px" color={"#748595"} />
              ) : (
                "Yes, delete"
              )}
            </button>

            <button className="negative" onClick={closeOverlay}>
              Don't delete
            </button>
          </div>
        </>
      ) : (
        <>
          <img src={deleteBin} alt="" />

          <div className="removed">Removed</div>

          <div className="message">
            You have succesfully removed <br /> {type?.toLowerCase()} from list
          </div>

          <div className="actions">
            <button className="negative" onClick={closeOverlay}>
              Close
            </button>
          </div>
        </>
      )}
    </Container>
  );
}

export default UserDeleteConfirmation;
