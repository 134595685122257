import styled from "styled-components";
import search from "assets/svg/search.svg";
import ActiveChatItem from "./active-chat-item.component";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  div.input-wrapper {
    width: auto;
    margin-right: 1.6rem;
    background: #e8ebed;
    border-radius: 12px;
    display: flex;
    height: 42px;
    margin: 2rem;
    flex-direction: row;
    align-items: center;
    padding: 1.8rem;

    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
    }
    input {
      display: inline-block;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      background: transparent;
      border: none;
      width: 330px;
      &:focus {
        outline: none;
      }
    }
  }

  div.list {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
`;

const ActiveChats = ({ rooms, messages, activeRoom, setActiveRoom }) => {
  const onSearch = (e) => {};

  return (
    <Container>
      <div className="input-wrapper">
        <img src={search} alt="" />
        <input type="text" placeholder="Search name" onChange={onSearch} />
      </div>

      <div className="list">
        {rooms instanceof Array &&
          rooms.map((room) => {
            let latestMessage = null;

            if (messages instanceof Array) {
              for (let i = messages.length - 1; i >= 0; i--) {
                if (messages[i].room === room.room) {
                  latestMessage = messages[i];
                  break;
                }
              }
            }

            console.log("Room", room, latestMessage);

            return (
              <ActiveChatItem
                key={room.room}
                room={room}
                latestMessage={latestMessage}
                active={activeRoom && activeRoom.room === room.room}
                setActiveRoom={setActiveRoom}
              />
            );
          })}
      </div>
    </Container>
  );
};

export default ActiveChats;
