import { AppContext } from "context/app.provider";
import { useContext, useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { ROUTES } from "utils/routes";
import { DesktopNavbar } from "./desktop-navbar.component";
import { Navbar } from "./navbar.component";
import socket from "utils/socket";
import { getToken } from "utils/firebase";

export const Layout = () => {
  const mediaQuery = window.matchMedia("(min-width: 800px)");
  const [showDesktopVersion, setShowDesktopVersion] = useState(
    mediaQuery.matches
  );

  const {
    currentUser,
    setAdmins,
    setUsers,
    setClients,
    setNewRequests,
    setRequests,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const isOnLogin = () => {
    return window.location.pathname === "/login";
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowDesktopVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  useEffect(() => {
    if (!currentUser && isOnLogin()) {
      return <Outlet />;
    }

    if (!currentUser && !isOnLogin()) {
      navigate(ROUTES.login);
    }

    if (currentUser && isOnLogin()) {
      navigate(ROUTES.home);
    }
  }, [currentUser]);

  const handleNewUser = (data) => {
    if (data.role?.toLowerCase() === "user") {
      setUsers((prev) => [data, ...prev]);
    } else if (data.role?.toLowerCase() === "admin") {
      if (currentUser.id !== data.created_by) {
        delete data.created_by;
        setAdmins((prev) => [data, ...prev]);
      }
    } else if (data.role?.toLowerCase() === "client") {
      if (currentUser.id !== data.created_by) {
        delete data.created_by;
        setClients((prev) => [data, ...prev]);
      }
    }
  };

  const handleNewRequest = (data) => {
    setNewRequests((prev) => prev + 1);

    setRequests((prev) => [data, ...prev]);
  };

  const handleAdminStatusChange = (data) => {
    if (!data.id || !data.newStatus) return;

    setAdmins((prev) =>
      prev.map((admin) => {
        if (admin.id === data.id) {
          return { ...admin, status: data.newStatus };
        }

        return admin;
      })
    );
  };

  const setUpdatesListener = async () => {
    console.log("connect");
    if (!socket.connected) {
      let accessToken = await getToken();
      if (accessToken) {
        socket.auth = { token: accessToken };
        socket.connect();
      }
    }

    if (!socket.hasListeners("new_user")) {
      socket.on("new_user", handleNewUser);
    }

    if (!socket.hasListeners("new_request")) {
      socket.on("new_request", handleNewRequest);
    }

    if (!socket.hasListeners("admin_status_change")) {
      socket.on("admin_status_change", handleAdminStatusChange);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setUpdatesListener();
    } else {
      console.log("disconnect");
      socket.removeAllListeners("new_user");
      socket.removeAllListeners("new_request");
      socket.removeAllListeners("admin_status_change");
      socket.disconnect();
    }

    return () => {
      console.log("disconnect");
      socket.removeAllListeners("new_user");
      socket.removeAllListeners("new_request");
      socket.removeAllListeners("admin_status_change");
      socket.disconnect();
    };
  }, [currentUser]);

  return isOnLogin() ? (
    <Outlet />
  ) : (
    <div>
      {showDesktopVersion ? (
        <DesktopNavbar Outlet={Outlet} />
      ) : (
        <>
          <Navbar />
          <Outlet />
        </>
      )}
    </div>
  );
};
