import React from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import rightIcon from "assets/svg/arrow-right-ios.svg";
import leftIcon from "assets/svg/arrow-left-ios.svg";

const Container = styled.div`
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0 5rem;
    cursor: pointer;
    max-width: 245px;
    font-size: 16px;
    color: #1c344f;

    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      cursor: pointer;
    }

    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }

    li.active a {
      background-color: #1c344f;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  }
`;

function PaginationComponent({ pageCount, handlePageClick }) {
  return (
    <Container>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<img src={rightIcon} alt="" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<img src={leftIcon} alt="" />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="pagination__link"
        nextClassName="pagination__next"
        previousClassName="pagination__previous"
        activeClassName="active"
      />
    </Container>
  );
}

export default PaginationComponent;
