import styled from "styled-components";
import search from "assets/svg/search.svg";
import plus from "assets/svg/plus.svg";
import { ClientRow } from "./client.row.component";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import { useEffect, useState } from "react";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";
import { useContext } from "react";
import { AppContext } from "context/app.provider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;
  }

  div.table-wrapper {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid #e8ebed;
        width: 100%;
      }

      td,
      th {
        &:last-child {
          padding-right: 2rem;
        }
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #1c344f;
        button {
          background: transparent;
          border: none;
          img {
            width: 20px;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;

        tr {
          background-color: #f4f5f6;
          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;

        color: #061927;
      }
      div.right {
        display: flex;
        align-items: center;
        div.input-wrapper {
          width: 360px;
          margin-right: 1.6rem;
          background: #e8ebed;
          box-sizing: border-box;
          height: 56px;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.8rem;

          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
          }
          input {
            display: inline-block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            background: transparent;
            border: none;
            width: 330px;
            &:focus {
              outline: none;
            }
          }
        }
        button:last-child {
          display: flex;
          align-items: center;
          background: #55b653;
          border-radius: 12px;
          border: none;
          height: 56px;
          padding: 1.8rem;
          margin-left: 1.6rem;
          margin-right: 1.6rem;
          width: 23rem;

          img {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 1.6rem;
          }
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }
`;

const getProductsRow = (products) => {
  console.log(products);
  let productsRow = "";
  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (i === 0) {
      productsRow += product.product_name;
    } else {
      productsRow += `, ${product.product_name}`;
    }
  }
  return productsRow;
};

export const ClientTable = ({
  clients,
  openOverlay,
  openEditOverlay,
  deleteClient,
}) => {
  const [filtered, setFiltered] = useState(clients);
  const { products } = useContext(AppContext);

  useEffect(() => {
    setFiltered(clients);
  }, [clients]);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredClients = () => {
    const result = clients.filter((client) => {
      const products = client.products?.map((product) =>
        product.product_name?.toLowerCase()
      );

      if (products instanceof Array) {
        if (
          products.some((val) =>
            activeFilter.map((item) => item.toLowerCase()).includes(val)
          )
        ) {
          return true;
        }
      }

      return false;
    });

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredClients();
    } else {
      setFiltered(clients);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredClients();
      } else {
        setFiltered(clients);
      }
    } else {
      const result = clients.filter((client) => {
        const fullname = client.firstname + " " + client.lastname;

        const phoneNo = getUserPhoneNumber(client);

        const products = client.products?.map((product) =>
          product.product_name?.toLowerCase()
        );

        if (
          client.email.toLowerCase().includes(query) ||
          client.firstname?.toLowerCase()?.includes(query) ||
          client.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (products instanceof Array && activeFilter.length > 0) {
            if (
              !products.some((val) =>
                activeFilter.map((item) => item.toLowerCase()).includes(val)
              )
            ) {
              return false;
            }
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      <header>
        <h1>Client List</h1>
        <div className="right">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>
          <FilterButton
            page={"client"}
            options={products.map((product) => product.product_name)}
            onSelect={onFilterSelected}
          />
          <button onClick={openOverlay}>
            <img src={plus} alt="" />
            <span>Add New Client</span>
          </button>
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="client"
        />
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>recyclable product</th>
              <th>email address</th>
              <th>registration date/time</th>
              <th>actions</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((client) => (
              <ClientRow
                key={client.id}
                removeClient={() => deleteClient(client.id, "client")}
                openEditOverlay={() => openEditOverlay(client)}
                name={client.firstname + " " + client.lastname}
                emailAddress={client.email}
                recyclableProduct={getProductsRow(client.products)}
                date={new Date(
                  client.created_at._seconds * 1000
                ).toTimeString()}
                phoneNo={getUserPhoneNumber(client)}
              />
            ))}
          </tbody>
        </table>
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={clients.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};
