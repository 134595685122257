import styled from "styled-components";

import { useContext, useEffect, useState } from "react";

import { RequestTable } from "components/tables/request/request-table.component";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";
import { AppContext } from "context/app.provider";
import { Toast, ToastStatus } from "components/toast.component";
import { Overlay } from "components/overlay.component";
import CompleteRequest from "components/overlays/complete-request.overlay";
import RequestMobile from "components/tables/request/request-mobile.component";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.table-container {
    display: none;
  }

  div.container {
    margin-right: 2rem;
    margin-left: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    section.requests {
      flex: 1;
    }
  }

  @media screen and (min-width: 1200px) {
    div.container {
      display: none;
    }
    div.table-container {
      display: block;
      margin-right: 2rem;
      margin-left: 2rem;
      height: 100%;
    }
  }
`;

export const Tabs = {
  Pending: 0,
  Ongoing: 1,
  History: 2,
};

export const RequestStates = {
  Pending: "Pending",
  Completed: "Completed",
  Declined: "Declined",
  Canceled: "Canceled",
  Ongoing: "Ongoing",
};

export const RecyclePage = () => {
  const mediaQuery = window.matchMedia("(min-width: 600px)");

  const [currentTab, setCurrentTab] = useState(Tabs.Pending);
  const [showTable, setShowTable] = useState(mediaQuery.matches);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [historyRequests, setHistoryRequests] = useState([]);
  const [ongoingRequests, setOngoingRequests] = useState([]);

  const { requests, setRequests, setNewRequests } = useContext(AppContext);

  const splitRequests = () => {
    let pending = [];
    let ongoing = [];
    let history = [];

    for (let request of requests) {
      if (request.status === RequestStates.Pending) {
        pending.push(request);
      } else if (request.status === RequestStates.Ongoing) {
        ongoing.push(request);
      } else {
        history.push(request);
      }
    }

    setHistoryRequests(history);
    setOngoingRequests(ongoing);
    setPendingRequests(pending);
  };

  useEffect(() => {
    splitRequests();
  }, [requests]);

  useEffect(() => {
    setNewRequests(0);

    const updateMatches = () => setShowTable(mediaQuery.matches);

    window.addEventListener("resize", updateMatches);

    return () => {
      window.removeEventListener("resize", updateMatches);
    };
  }, []);

  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState(ToastStatus.failed);

  const openToast = (msg, status = ToastStatus.failed) => {
    setToastMessage(msg);
    setToastStatus(status);

    setTimeout(() => {
      setToastMessage("");
    }, 4000);
  };

  const changeRequestStatus = async ({
    request_id,
    status,
    quantity = null,
  }) => {
    try {
      let accessToken = await getToken();

      if (!accessToken) return;

      const res = await axiosInstance.patch(
        "/admin/pickup-request",
        {
          request_id,
          status,
          quantity: Number(quantity),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.log(error);
      if (error.response) {
        openToast(error.response.data.message);
      }
    }
  };

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedRequestProductClass, setSelectedRequestProductClass] =
    useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedRequestQuantity, setSelectedRequestQuantity] = useState(null);

  const openOverlay = (product_class, quantity, request_id) => {
    setSelectedRequestProductClass(product_class);
    setSelectedRequestQuantity(quantity);
    setSelectedRequestId(request_id);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setSelectedRequestProductClass(null);
    setSelectedRequestQuantity(null);
    setSelectedRequestId(null);
    setIsOverlayOpen(false);
  };

  const completeRequest = async (quantity) => {
    if (selectedRequestId === null) return null;

    const result = await changeRequestStatus({
      request_id: selectedRequestId,
      status: "Completed",
      quantity,
    });

    if (result?.success) {
      openToast(result.message, ToastStatus.success);

      setRequests((prev) =>
        prev.map((item) => {
          if (item.id === selectedRequestId) {
            return { ...item, status: "Completed", quantity };
          }

          return item;
        })
      );

      closeOverlay();
    }
  };

  return (
    <Container>
      {toastMessage && (
        <Toast
          text={toastMessage}
          timeout={3000}
          toastStatus={toastStatus}
          width="400px"
        />
      )}
      <Overlay
        closeOverlay={closeOverlay}
        isVisible={isOverlayOpen}
        Component={CompleteRequest}
        extraProps={{
          originalQuantity: selectedRequestQuantity,
          productClass: selectedRequestProductClass,
          completeRequest,
        }}
      />
      <div className="container">
        <section className="requests">
          <RequestMobile
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            pendingRequests={pendingRequests}
            ongoingRequests={ongoingRequests}
            historyRequests={historyRequests}
            changeRequestStatus={changeRequestStatus}
            openToast={openToast}
            openOverlay={openOverlay}
          />
        </section>
      </div>
      {showTable && (
        <div className="table-container">
          <RequestTable
            page="request"
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            pendingRequests={pendingRequests}
            ongoingRequests={ongoingRequests}
            historyRequests={historyRequests}
            changeRequestStatus={changeRequestStatus}
            openToast={openToast}
            openOverlay={openOverlay}
          />
        </div>
      )}
    </Container>
  );
};
