import styled from "styled-components";
import search from "assets/svg/search.svg";
import FilterButton from "components/filter-button.component";
import { UserCard } from "components/user-card.component";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import requestImage1 from "assets/png/requestImage1.png";
import ActiveFilter from "components/active-filter.component";
import { useEffect, useState } from "react";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import { Toast, ToastStatus } from "components/toast.component";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  header {
    border-bottom: 1px solid #dce2e6;
    padding-bottom: 2rem;
    & > h1 {
      font-size: 18px;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: auto;
    }

    div.actions {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      marging-bottom: 1rem;

      div.input-wrapper {
        width: 360px;
        margin-right: 1.6rem;
        background: #e8ebed;
        box-sizing: border-box;
        height: 56px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.8rem;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 10px;
        }

        input {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          background: transparent;
          border: none;
          width: 60%;
          &:focus {
            outline: none;
          }
        }
      }
    }

    button.tab {
      background-color: transparent;
      border: none;
      margin-right: 2.4rem;
      font-weight: 400;
      font-size: 14px;
      color: #919eab;
      height: 56px;
      &.active {
        color: #1c344f;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          background: #55b653;
          bottom: 0px;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  div.main {
    flex: 1;
  }

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 1rem;
    margin-left: 1rem;

    position: sticky;
    bottom: 0;

    background-color: white;

    @media screen and (min-width: 900px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

const UserMobile = ({
  users = [],
  editUser,
  deleteUser,
  showPointsInfo,
  setPointsInfo,
}) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [filtered, setFiltered] = useState(users);

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredUser = () => {
    const result = users.filter((user) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(user.status.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredUser();
    } else {
      setFiltered(users);
    }
  }, [activeFilter]);

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredUser();
      } else {
        setFiltered(users);
      }
    } else {
      const result = users.filter((user) => {
        const fullname = user.firstname + " " + user.lastname;

        const address = user.default_address
          ? `${user.default_address.address_line1}, ${user.default_address.city}, ${user.default_address.state}`
          : "";

        const phoneNo = getUserPhoneNumber(user);

        if (
          user.email.toLowerCase().includes(query) ||
          user.firstname?.toLowerCase()?.includes(query) ||
          user.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          address.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(user.status.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  return (
    <Container>
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="80%"
        />
      )}
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="80%"
        />
      )}

      <header>
        <h1>User List</h1>

        <div className="actions">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>
          <FilterButton
            page={"user"}
            options={["Enabled", "Disabled"]}
            onSelect={onFilterSelected}
          />
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="recycle"
        />
      )}

      <div className="main">
        {currentItems.map((user) => (
          <UserCard
            key={user.id}
            id={user.id}
            name={`${user.firstname} ${user.lastname}`}
            status={user.status}
            email={user.email}
            editUser={editUser}
            number={getUserPhoneNumber(user)}
            points={user.points}
            referral_points={user.referral_points}
            imageUrl={user.avatar || requestImage1}
            setSuccess={setSuccess}
            setError={setError}
            deleteUser={deleteUser}
            showPointsInfo={showPointsInfo}
            setPointsInfo={setPointsInfo}
          />
        ))}
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={filtered.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};

export default UserMobile;
