import styled from "styled-components";

import plusIcon from "assets/svg/plus.svg";
import { ProductCard } from "components/product-card.component";
import { ProductTable } from "components/tables/product/product-table.component";
import { useEffect, useState } from "react";
import { Overlay } from "components/overlay.component";
import { NewProduct } from "components/overlays/new-product.overlay";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import { Toast, ToastStatus } from "components/toast.component";
import ProductDeleteConfirmation from "components/overlays/product-delete.overlay";
import ProductMobile from "components/tables/product/product-mobile";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.table-container {
    display: none;
  }

  div.container {
    margin-right: 2rem;
    margin-left: 2rem;
    height: 100%;
  }

  @media screen and (min-width: 1200px) {
    div.container {
      display: none;
    }
    div.table-container {
      display: block;
      margin-right: 2rem;
      margin-left: 2rem;
      height: 100%;
    }
  }
`;

export const ProductsPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);

  const { products, setProducts } = useContext(AppContext);

  const addProduct = (product) => {
    setProducts([product, ...products]);
  };

  const editProduct = (data) => {
    setProducts((prev) =>
      prev.map((product) => {
        if (product.id === data.product_id) {
          delete data.product_id;
          return { ...product, ...data };
        }

        return product;
      })
    );
  };

  const openEditOverlay = (product) => {
    setEditData(product);
    setIsEditMode(true);
    setIsOverlayOpen(true);
  };

  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);
  const [error, setError] = useState("");
  const [productId, setProductId] = useState(null);

  const showDeleteOverlay = (id) => {
    setProductId(id);
    setIsDeleteOverlayOpen(true);
  };

  const resetOverlay = () => {
    setIsOverlayOpen(false);
    setIsEditMode(false);
    setEditData(null);

    setError("");
    setProductId(null);
    setIsDeleteOverlayOpen(false);
  };

  useEffect(() => {
    return resetOverlay;
  }, []);

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <Overlay
        closeOverlay={resetOverlay}
        isVisible={isOverlayOpen}
        Component={NewProduct}
        extraProps={{
          addProduct,
          isEditMode,
          editData,
          editProduct,
        }}
      />

      <Overlay
        closeOverlay={() => setIsDeleteOverlayOpen(false)}
        isVisible={isDeleteOverlayOpen}
        Component={ProductDeleteConfirmation}
        extraProps={{ setError, productId }}
      />

      <div className="container">
        <ProductMobile
          openOverlay={() => setIsOverlayOpen(true)}
          products={products}
          openEditOverlay={openEditOverlay}
          openDeleteOverlay={showDeleteOverlay}
        />
        {/* <header>
          <h1>Products</h1>
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsOverlayOpen(true);
            }}
          >
            <img src={plusIcon} alt="" />
            <span>Add New Product</span>
          </button>
        </header> */}
        {/* <section className="products">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              name={product.product_name}
              productClass={product.product_class}
              points={product.points_per_unit}
              productId={product.id}
              productMinQty={`${
                product.min_quantity
              } ${product.unit.toUpperCase()}`}
            />
          ))}
        </section> */}
      </div>
      <div className="table-container">
        <ProductTable
          openOverlay={() => setIsOverlayOpen(true)}
          products={products}
          openEditOverlay={openEditOverlay}
          openDeleteOverlay={showDeleteOverlay}
        />
      </div>
    </Container>
  );
};
