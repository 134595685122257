import React from "react";
import styled from "styled-components";
import cancel from "assets/svg/green-cancel-icon.svg";

const Container = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 2rem;
  gap: 1rem;

  div.filter-tab {
    background-color: #f1f9f1;
    border-radius: 60px;
    padding: 1.4rem;
    margin-left: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(1) {
      margin-left: 0;
    }

    span {
      display: block;
      color: #55b653;
      font-size: 1.4rem;
    }

    img {
      cursor: pointer;
      display: block;
      margin-left: 6px;
      margin-top: auto;
    }
  }

  span.clear-all {
    display: block;
    color: #313840;
    font-size: 1.4rem;
    margin-left: 1.6rem;
    cursor: pointer;
  }

  @media screen and (min-width: 900px) {
    padding: 2rem;
    padding-top: 0;
    margin-top: 0;
  }
`;

function ActiveFilter({ clearFilter, removeFilter, activeFilters, page }) {
  return (
    <Container>
      {activeFilters.map((item, idx) => (
        <div key={page + "-active-filter-tab-" + idx} className="filter-tab">
          <span>{item}</span>

          <img src={cancel} onClick={() => removeFilter(item)} />
        </div>
      ))}

      <span className="clear-all" onClick={clearFilter}>
        Clear Filters
      </span>
    </Container>
  );
}

export default ActiveFilter;
