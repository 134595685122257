import { io } from "socket.io-client";

// const URL = "https://localhost:4000";
const URL = "https://recyclo.onrender.com";

const socket = io(URL, { autoConnect: false });

socket.onAny((event) => console.log("event", event));

socket.on("connect", () => {
  console.log(socket.connected); // true
});

socket.on("disconnect", () => {
  console.log(socket.connected); // false
});

export default socket;
