import React from "react";
import ReactMultiSelect from "multiselect-react-dropdown";

function MultiSelect({
  options = [],
  selectedValues = [],
  onSelect,
  onRemove,
  displayValue,
  placeholder,
}) {
  return (
    <ReactMultiSelect
      options={options}
      selectedValues={selectedValues}
      onSelect={onSelect}
      onRemove={onRemove}
      displayValue={displayValue}
      placeholder={placeholder}
      // showArrow
      style={{
        searchBox: {
          borderColor: "#e8ebed",
          backgroundColor: "#f8f9fa",
          padding: "1.2rem 1.8rem",
          borderRadius: 8,
          height: 56,
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        },
        chips: {
          backgroundColor: "#55B653",
          color: "#fff",
        },
        inputField: {
          fontSize: 16,
        },
      }}
    />
  );
}

export default MultiSelect;
