import { Toggler } from "components/toggler.component";
import { AppContext } from "context/app.provider";
import { useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import { axiosInstance } from "utils/api";
import { getToken } from "utils/firebase";
import { SettingsCard } from "./settings-card.component";

const Container = styled.div``;

const Btn = styled.button`
  background: #f8fcf8;
  border: 1px solid #bfe4be;
  border-radius: 8px;
  height: 54px;
  font-weight: 400;
  font-size: 14px;
  color: #1c344f;
  padding: 1rem 3rem;
`;

export const Security = ({ openOverlay }) => {
  const [is2FAActive, setIs2FAActive] = useState(false);

  const { currentUser, setCurrentUser } = useContext(AppContext);

  const Button = () => {
    return <Btn onClick={openOverlay}>Change Password</Btn>;
  };

  const toggleTfa = async () => {
    if (!currentUser) return;

    try {
      let accessToken = await getToken();

      if (!accessToken) return;

      const res = await axiosInstance.patch(
        "/user",
        { tfa: !currentUser.tfa },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (res.status === 200) {
        setCurrentUser((prev) => ({ ...prev, tfa: !currentUser.tfa }));
      }
    } catch (error) {}
  };

  return (
    <Container>
      <SettingsCard
        Button={<Button />}
        title="update your password"
        subtitle="Update your old password
to a new one"
        active={is2FAActive}
        setActive={setIs2FAActive}
      />
      <SettingsCard
        Button={
          <Toggler active={currentUser?.tfa || false} setActive={toggleTfa} />
        }
        title="two-factor verification"
        subtitle="Protect your account from
unauthorized users"
        active={is2FAActive}
        setActive={setIs2FAActive}
      />
    </Container>
  );
};
