import PaginationComponent from "components/pagination/pagination-component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import { RequestCard } from "components/request-card.component";
import { AppContext } from "context/app.provider";
import { Tabs } from "pages/recycle.page";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { usePagination } from "utils/shared";
import search from "assets/svg/search.svg";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  header {
    border-bottom: 1px solid #dce2e6;
    & > h1 {
      font-size: 18px;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: auto;
    }

    div.actions {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      marging-bottom: 1rem;

      div.input-wrapper {
        width: 360px;
        margin-right: 1.6rem;
        background: #e8ebed;
        box-sizing: border-box;
        height: 56px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.8rem;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 10px;
        }

        input {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          background: transparent;
          border: none;
          width: 60%;
          &:focus {
            outline: none;
          }
        }
      }
    }

    button.tab {
      background-color: transparent;
      border: none;
      margin-right: 2.4rem;
      font-weight: 400;
      font-size: 14px;
      color: #919eab;
      height: 56px;
      &.active {
        color: #1c344f;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          background: #55b653;
          bottom: 0px;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  div.main {
    flex: 1;
  }

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 1rem;
    margin-left: 1rem;

    position: sticky;
    bottom: 0;

    background-color: white;

    @media screen and (min-width: 900px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

const RequestMobile = ({
  currentTab,
  setCurrentTab,
  openOverlay,
  openToast,
  changeRequestStatus,
  pendingRequests,
  ongoingRequests,
  historyRequests,
}) => {
  const currentTabRequests = useMemo(() => {
    if (currentTab === Tabs.Pending) {
      return pendingRequests;
    } else if (currentTab === Tabs.Ongoing) {
      return ongoingRequests;
    } else if (currentTab === Tabs.History) {
      return historyRequests;
    }

    return [];
  }, [currentTab, pendingRequests, ongoingRequests, historyRequests]);

  const [filtered, setFiltered] = useState(currentTabRequests);

  useEffect(() => {
    setFiltered(currentTabRequests);
  }, [currentTabRequests]);

  const { products } = useContext(AppContext);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
    reset,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredRequest = () => {
    const result = currentTabRequests.filter((request) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(request.product?.product_name?.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredRequest();
    } else {
      setFiltered(currentTabRequests);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredRequest();
      } else {
        setFiltered(currentTabRequests);
      }
    } else {
      const result = currentTabRequests.filter((request) => {
        const fullname =
          request.requested_by.firstname + " " + request.requested_by.lastname;

        const address = request.address
          ? `${request.address.address_line1}, ${request.address.city}, ${request.address.state}`
          : "";

        if (
          request.requested_by.firstname?.toLowerCase()?.includes(query) ||
          request.requested_by.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          address.toLowerCase().includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(request.product?.product_name?.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  useEffect(() => {
    if (currentTab) {
      reset();
      setActiveFilter([]);
    }
  }, [currentTab]);

  return (
    <Container>
      <header>
        <h1>Recycle</h1>

        <div className="actions">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>
          <FilterButton
            page={"recycle"}
            options={products.map((product) => product.product_name)}
            onSelect={onFilterSelected}
          />
        </div>

        <nav>
          <button
            className={`tab ${currentTab === Tabs.Pending ? "active" : ""}`}
            onClick={() => setCurrentTab(Tabs.Pending)}
          >
            Pending
          </button>
          <button
            className={`tab ${currentTab === Tabs.Ongoing ? "active" : ""}`}
            onClick={() => setCurrentTab(Tabs.Ongoing)}
          >
            Ongoing
          </button>
          <button
            className={`tab ${currentTab === Tabs.History ? "active" : ""}`}
            onClick={() => setCurrentTab(Tabs.History)}
          >
            History
          </button>
        </nav>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="recycle"
        />
      )}

      <div className="main">
        {currentItems.map((request) => {
          return (
            <RequestCard
              key={"top-three-" + request.id}
              id={request.id}
              name={`${request.requested_by.firstname} ${request.requested_by.lastname}`}
              requestName={request.product.product_name}
              date={new Date(request.created_at._seconds * 1000).toDateString()}
              quantity={request.quantity}
              requestImage={request.requested_by.avatar}
              number={request.requested_by.phonenumber}
              status={request.status}
              address={
                request.address
                  ? `${request.address.address_line1}, ${request.address.city}, ${request.address.country}`
                  : ""
              }
              changeRequestStatus={changeRequestStatus}
              openToast={openToast}
              openComplete={openOverlay}
              productClass={request.product.product_class}
            />
          );
        })}
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={filtered.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};

export default RequestMobile;
