import styled from "styled-components";

import cancelIcon from "assets/svg/cancel.svg";
import requestImage1 from "assets/png/requestImage1.png";
import { getRequestStatusFromAction } from "utils/shared";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import { ToastStatus } from "components/toast.component";
import { RequestAction } from "components/tables/request/request.row.component";
import { useState } from "react";
import { MiniLoader } from "components/loader.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  background-color: #fff;
  border-radius: 10px;
  padding: 2.5rem;

  div.header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 3rem;

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  div.content {
    div.top {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      img {
        display: inline-block;
        margin-right: 10px;
      }

      div.right {
        display: inline-block;
        em {
          display: block;
          font-style: normal;
          &:first-child {
            margin-bottom: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #1c344f;
          }
          &:last-child {
            font-size: 12px;
            color: #748595;
          }
        }
      }
    }

    div.mid {
      margin-bottom: 2rem;
      div.row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        div {
          em {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #748595;
            margin-bottom: 1.2rem;
            text-transform: uppercase;
          }
          p {
            font-weight: 400;
            font-size: 12px;
            color: #1c344f;
          }
        }
      }

      div.row-2 {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        em {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
          margin-bottom: 1.2rem;
          text-transform: uppercase;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          color: #1c344f;
        }
      }
    }

    div.buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;

      button {
        font-weight: 400;
        font-size: 14px;
        border-radius: 5px;
        border: none;
        padding: 1rem;
        width: 105px;
        &:first-child {
          background: #ffffff;
          color: #7ac678;
          border: solid 1px #7ac678;
        }
        &:last-child {
          color: #ffffff;
          background: #7ac678;
        }
      }
    }
  }
`;

export const RecycleRequestConfirmation = ({
  id,
  closeOverlay,
  name,
  number,
  date,
  quantity,
  requestImage,
  requestName,
  status = "Pending",
  address,
  changeRequestStatus,
  openToast,
  productClass,
  openComplete,
}) => {
  const { setRequests } = useContext(AppContext);

  const changeRequestStatusHandler = async (option) => {
    const status = getRequestStatusFromAction(option);

    const result = await changeRequestStatus({
      status,
      request_id: id,
    });

    if (result.success) {
      openToast(result.message, ToastStatus.success);

      setRequests((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return { ...item, status };
          }

          return item;
        })
      );
    }
  };

  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const acceptRequest = async () => {
    setAccepting(true);

    await changeRequestStatusHandler(RequestAction.Accept);

    setAccepting(false);
  };

  const declineRequest = async () => {
    setDeclining(true);

    await changeRequestStatusHandler(RequestAction.Decline);

    setDeclining(false);
  };

  const cancelRequest = async () => {
    setCancelling(true);

    await changeRequestStatus(RequestAction.Cancel);

    setCancelling(false);
  };

  const completeRequest = () => {
    closeOverlay();

    openComplete(productClass, quantity, id);
  };

  return (
    <Container>
      <div className="header">
        <button
          onClick={(e) => {
            e.stopPropagation();
            closeOverlay();
          }}
        >
          <img src={cancelIcon} alt="" />
        </button>
      </div>

      <div className="content">
        <div className="top">
          <img
            src={requestImage || requestImage1}
            alt="recycle request owner"
          />
          <div className="right">
            <em>{name}</em>
            <em>{number}</em>
          </div>
        </div>

        <div className="mid">
          <div className="row-1">
            <div className="left">
              <em>Garbage Type</em>
              <p>{requestName}</p>
            </div>
            <div className="right">
              <em>Quantity</em>
              <p>{quantity}</p>
            </div>
          </div>

          <div className="row-2">
            <em>Address</em>
            <p>{address}</p>
          </div>

          <div className="row-2">
            <em>Initiation Date/Time</em>
            <p>{date}</p>
          </div>
        </div>

        <div className="buttons">
          {status.toLowerCase() === "pending" && (
            <>
              <button
                onClick={declineRequest}
                disabled={declining || accepting}
              >
                {declining ? <MiniLoader color="#7AC678" /> : "Decline"}
              </button>
              <button onClick={acceptRequest} disabled={accepting || declining}>
                {accepting ? <MiniLoader color="#ffffff" /> : "Accept"}
              </button>
            </>
          )}

          {status.toLowerCase() === "ongoing" && (
            <>
              <button onClick={cancelRequest} disabled={cancelling}>
                {cancelling ? <MiniLoader color="#7AC678" /> : "Cancel"}
              </button>
              <button disabled={cancelling} onClick={completeRequest}>
                Complete
              </button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};
