import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  div.left {
    width: 350px;
    p {
      &:first-child {
        font-weight: 400;
        font-size: 1.4rem;
        color: #1c344f;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      &:last-child {
        font-weight: 400;
        font-size: 1.4rem;
        color: #748595;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 2rem 0;
  }
`;

export const SettingsCard = ({ Button, title, subtitle }) => {
  return (
    <Container>
      <div className="left">
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
      {Button}
    </Container>
  );
};
