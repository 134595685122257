import React from "react";
import styled from "styled-components";
import horizontalDots from "assets/svg/horizontal-dots.svg";
import { useRef } from "react";
import Popup from "reactjs-popup";

const Option = styled.div`
  padding: 1rem;
  cursor: pointer;
  color: #1c344f;
  font-size: 1.2rem;

  &:hover {
    background: #ccc;
  }

  @media screen and (min-width: 1000px) {
    font-size: 1.6rem;
  }
`;

function DropdownMenu({ options = [], onSelected }) {
  const ref = useRef(null);

  const closeTooltip = () => ref.current?.close();

  const handleClick = (value) => () => {
    closeTooltip();

    onSelected(value);
  };

  return (
    <Popup
      ref={ref}
      position={"bottom center"}
      closeOnDocumentClick
      trigger={
        <img
          src={horizontalDots}
          alt=""
          style={{ width: 25, height: 20, cursor: "pointer" }}
        />
      }
    >
      {options.map((option, idx) => (
        <Option key={Math.random() + idx} onClick={handleClick(option)}>
          {option}
        </Option>
      ))}
    </Popup>
  );

  // return (
  //   <Container ref={dropdownRef} class="dropdown" onClick={openOptions}>
  //     <img src={horizontalDots} alt="" />

  //     {isOptionsOpen && (
  //       <div class="dropdown-content">
  //         {options.map((item, idx) => (
  //           <div
  //             key={Date.now() + Math.random() + idx}
  //             onClick={handleClick(item)}
  //           >
  //             {item}
  //           </div>
  //         ))}
  //       </div>
  //     )}
  //   </Container>
  // );
}

export default DropdownMenu;
