import styled from "styled-components";

import { useState } from "react";

import { SettingsForm } from "components/settings/settings-form.component";
import { Security } from "components/settings/security.component";
import { Toast, ToastStatus } from "components/toast.component";
import { Overlay } from "components/overlay.component";
import { ChangePassword } from "components/overlays/change-password.overlay";

const Container = styled.div`
  & > header {
    padding: 4rem 2.5rem 3.2rem;

    h1 {
      font-size: 3.6rem;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: 2.6rem;
    }

    button {
      background-color: transparent;
      border: none;
      margin-right: 2.4rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #919eab;
      &.active {
        color: #1c344f;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          background: #55b653;
          bottom: -5px;
          left: 0;
          width: 90%;
        }
      }
    }
  }
  section.requests {
    padding: 2.5rem 2rem;
    max-width: 580px;
    form {
      label {
        font-weight: 400;
        font-size: 12px;
        color: #1c344f;
      }
    }
  }
`;

const Tabs = {
  AccountDetails: 0,
  Security: 2,
};

export const SettingsPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(Tabs.AccountDetails);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <Container>
      <Overlay
        closeOverlay={() => setIsOverlayOpen(false)}
        isVisible={isOverlayOpen}
        Component={ChangePassword}
        extraProps={{
          setSuccess: (success) => {
            setSuccess(success);
            setTimeout(() => {
              ("");
            }, 4000);
          },
          setError: (error) => {
            setError(error);
            setTimeout(() => {
              setError("");
            }, 4000);
          },
          closeOverlay: () => setIsOverlayOpen(false),
        }}
      />

      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <header>
        <h1>Settings</h1>

        <nav>
          <button
            className={currentTab === Tabs.AccountDetails ? "active" : ""}
            onClick={() => setCurrentTab(Tabs.AccountDetails)}
          >
            Account Details
          </button>
          <button
            className={currentTab === Tabs.Security ? "active" : ""}
            onClick={() => setCurrentTab(Tabs.Security)}
          >
            Security
          </button>
        </nav>
      </header>

      <section className="requests">
        {currentTab === Tabs.AccountDetails && (
          <SettingsForm setSuccess={setSuccess} setError={setError} />
        )}

        {currentTab === Tabs.Security && (
          <Security
            closeOverlay={() => setIsOverlayOpen(false)}
            openOverlay={() => setIsOverlayOpen(true)}
          />
        )}
      </section>
    </Container>
  );
};
