import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8ebed;

  .filled {
    height: 1px;
    width: ${(props) => props.filled}%;
    background-color: #55b653;
    transition: width 1s;
  }
`;

function LinearIndicator({ value = 0 }) {
  return (
    <Container filled={Math.round(value * 100)}>
      <div className="filled" />
    </Container>
  );
}

export default LinearIndicator;
