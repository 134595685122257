import { initializeApp } from "firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import {
  getStorage,
  uploadBytes,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { useEffect, useState } from "react";

console.log(process.env);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export const signIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return "success";
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log({ errorCode, errorMessage });
    if (errorCode === "auth/wrong-password") {
      return "auth-mismatch";
    }
    if (errorCode === "auth/too-many-requests") {
      return "too-many";
    }
  }
};

export const getToken = async () => {
  if (!auth.currentUser) return;
  let token = await auth.currentUser.getIdToken();
  return token;
};

export const logOut = () => {
  signOut(auth);
  localStorage.removeItem("tfaVerified");
};

export const changePassword = async (newPassword) => {
  const auth = getAuth();

  const user = auth.currentUser;

  try {
    let response = await updatePassword(user, newPassword);
    console.log(response);
    return "success";
  } catch (e) {
    console.log(e.code);
    if (e.code === "auth/requires-recent-login") return "reauthenticate";
  }
  return "error";
};

const storage = getStorage(app);

export const uploadImage = async (file, path, callback) => {
  const imageRef = ref(storage, path);
  try {
    await uploadBytes(imageRef, file);
    const resp = await getDownloadURL(imageRef);
    if (callback) {
      callback("Uploaded Successfully");
      setTimeout(() => {
        callback("");
      }, 3_000);
    }
    return resp;
  } catch (e) {
    console.log(e);
  }
};

export const useUploadWithProgress = (onError, onComplete) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);

  const upload = async (file, path) => {
    setUploading(true);

    try {
      const storageRef = ref(storage, path);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setProgress(progress);
          if (snapshot.totalBytes != totalBytes) {
            setTotalBytes(snapshot.totalBytes);
          }
        },
        (error) => {
          setUploading(false);
          console.log("Upload error", error);
          onError("Error uploading file. Try again.");
        },
        async () => {
          const link = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          onComplete(link);
          reset();
        }
      );
    } catch (e) {
      setUploading(false);
      onError(e?.toString());
    }
  };

  const reset = () => {
    setTotalBytes(0);
    setUploading(false);
    setProgress(0);
  };

  useEffect(() => {
    return reset;
  }, []);

  return { upload, uploading, progress, totalBytes };
};

// const twitterProvider = new firebase.auth.TwitterAuthProvider();

// export const signInWithTwitter = () => auth.signInWithPopup(twitterProvider);
