import { AppContext } from "context/app.provider";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import search from "assets/svg/search.svg";
import FilterButton from "components/filter-button.component";
import plusIcon from "assets/svg/plus.svg";
import { ClientCard } from "components/client-card.component";
import ActiveFilter from "components/active-filter.component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  header {
    border-bottom: 1px solid #dce2e6;
    padding-bottom: 2rem;
    & > h1 {
      font-size: 18px;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: auto;
    }

    div.actions {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      marging-bottom: 1rem;

      div.input-wrapper {
        width: 360px;
        margin-right: 1.6rem;
        background: #e8ebed;
        box-sizing: border-box;
        height: 56px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.8rem;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 10px;
        }

        input {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          background: transparent;
          border: none;
          width: 60%;
          &:focus {
            outline: none;
          }
        }
      }
    }

    button.add {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      background: #55b653;
      border-radius: 12px;
      border: none;
      width: unset;
      height: 56px;
      padding: 1.8rem;

      img {
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }
  }

  div.main {
    flex: 1;
  }

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 1rem;
    margin-left: 1rem;

    position: sticky;
    bottom: 0;

    background-color: white;

    @media screen and (min-width: 900px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

const getProductsRow = (products) => {
  console.log(products);
  let productsRow = "";
  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (i === 0) {
      productsRow += product.product_name;
    } else {
      productsRow += `, ${product.product_name}`;
    }
  }
  return productsRow;
};

const ClientMobile = ({
  clients,
  openOverlay,
  openEditOverlay,
  deleteClient,
}) => {
  const [filtered, setFiltered] = useState(clients);
  const { products } = useContext(AppContext);

  useEffect(() => {
    setFiltered(clients);
  }, [clients]);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredClients = () => {
    const result = clients.filter((client) => {
      const products = client.products?.map((product) =>
        product.product_name?.toLowerCase()
      );

      if (products instanceof Array) {
        if (
          products.some((val) =>
            activeFilter.map((item) => item.toLowerCase()).includes(val)
          )
        ) {
          return true;
        }
      }

      return false;
    });

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredClients();
    } else {
      setFiltered(clients);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredClients();
      } else {
        setFiltered(clients);
      }
    } else {
      const result = clients.filter((client) => {
        const fullname = client.firstname + " " + client.lastname;

        const phoneNo = getUserPhoneNumber(client);

        const products = client.products?.map((product) =>
          product.product_name?.toLowerCase()
        );

        if (
          client.email.toLowerCase().includes(query) ||
          client.firstname?.toLowerCase()?.includes(query) ||
          client.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (products instanceof Array && activeFilter.length > 0) {
            if (
              !products.some((val) =>
                activeFilter.map((item) => item.toLowerCase()).includes(val)
              )
            ) {
              return false;
            }
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      <header>
        <h1>Client List</h1>

        <div className="actions">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>

          <FilterButton
            page={"client"}
            options={products.map((product) => product.product_name)}
            onSelect={onFilterSelected}
          />

          <button
            className="add"
            onClick={(e) => {
              e.preventDefault();
              openOverlay();
            }}
          >
            <img src={plusIcon} alt="" />
          </button>
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="client"
        />
      )}

      <div className="main">
        {currentItems.map((client) => (
          <ClientCard
            key={client.id}
            name={client.firstname + " " + client.lastname}
            productType={getProductsRow(client.products)}
            email={client.email}
            removeClient={() => deleteClient(client.id, "client")}
            openEditOverlay={() => openEditOverlay(client)}
            number={client.phonenumber}
          />
        ))}
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={clients.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};

export default ClientMobile;
