import { Toast, ToastStatus } from "components/toast.component";
import { useState } from "react";
import styled from "styled-components";
import ActiveChats from "./active-chats.component";
import ChatScreen from "./chat-screen.component";
import { MessageTab, Tab } from "./desktop.component";
import RequestActionComponent from "./request-action.component";
import RequestQueue from "./request-queue.component";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.lists {
    height: 100%;

    header {
      height: 100px;
      width: 100%;
      display: flex;
      flex-direction: column;

      h5 {
        font-size: 1.6rem;
        display: block;
        color: #1c344f;
        flex: 1;
        font-weight: 500;

        margin: 2rem;
        margin-bottom: 0;
      }

      div.tabs {
        display: flex;
        flex-direction: row;
      }
    }

    div.tab-list {
      height: calc(100% - 100px);
    }
  }
`;

const MobileLiveChat = ({
  rooms,
  requests,
  messages,
  activeRoom,
  setActiveRoom,
  activeRequest,
  setActiveRequest,
  deleteRequest,
  handleNewMessage,
  terminating,
  terminateActiveRoom,
}) => {
  const [activeTab, setActiveTab] = useState(MessageTab.ActiveChats);

  const [error, setError] = useState("");

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={4000}
          width="80%"
          toastStatus={ToastStatus.failed}
        />
      )}

      {!activeRequest && !activeRoom && (
        <div className="lists">
          <header>
            <h5>Messages</h5>

            <div className="tabs">
              <Tab
                active={activeTab === MessageTab.ActiveChats}
                onClick={() => setActiveTab(MessageTab.ActiveChats)}
              >
                <div className="title">Active Chats</div>
              </Tab>

              <Tab
                active={activeTab === MessageTab.Queue}
                onClick={() => setActiveTab(MessageTab.Queue)}
              >
                <div className="title">
                  Queue ({requests instanceof Array ? requests.length : 0})
                </div>
              </Tab>
            </div>
          </header>

          <div className="tab-list">
            {activeTab === MessageTab.ActiveChats && (
              <ActiveChats
                rooms={rooms}
                messages={messages}
                activeRoom={activeRoom}
                setActiveRoom={setActiveRoom}
              />
            )}

            {activeTab === MessageTab.Queue && (
              <RequestQueue
                requests={requests}
                activeRequest={activeRequest}
                setActiveRequest={setActiveRequest}
              />
            )}
          </div>
        </div>
      )}

      {activeRequest && (
        <RequestActionComponent
          req={activeRequest}
          deleteRequest={deleteRequest}
          setActiveRequest={setActiveRequest}
          setError={setError}
          goBack={() => setActiveRequest(null)}
        />
      )}

      {activeRoom && (
        <ChatScreen
          room={activeRoom}
          handleNewMessage={handleNewMessage}
          terminating={terminating}
          terminateActiveRoom={terminateActiveRoom}
          goBack={() => setActiveRoom(null)}
        />
      )}
    </Container>
  );
};

export default MobileLiveChat;
