import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import { useUploadWithProgress } from "utils/firebase";
import galleryIcon from "assets/svg/gallery.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const fileTypes = ["JPG", "PNG"];

const Container = styled.div`
  div.fileUpload {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background: #f8f9fa;
    padding: 3rem;
    border-radius: 8px;
    font-size: 1.4rem;
    color: #5c6a78;
    cursor: pointer;
    margin-bottom: 3rem;
    border: 1px dashed #e8ebed;

    img.uploadedImage {
      height: 82px;
      width: 82px;
      object-fit: cover;
    }

    div.uploadData {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      span {
        font-size: 1.6rem;
        letter-spacing: -0.02em;
        color: #5c6a78;

        &:nth-child(2) {
          margin-top: 0.2rem;
          font-size: 12px;
          color: #919eab;
        }
      }

      span.change {
        color: #55b653;
        cursor: pointer;
      }
    }
  }

  label.fileSelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f8f9fa;
    padding: 3rem;
    border-radius: 8px;
    font-size: 1.4rem;
    color: #5c6a78;
    cursor: pointer;
    margin-bottom: 3rem;
    border: 1px dashed #e8ebed;

    img {
      margin-bottom: 2rem;
    }

    span {
      display: block;
      margin-bottom: 2rem;
    }

    span.small {
      font-size: 0.8rem;
      color: #919eab;
    }
  }
`;

export const DragDrop = ({ url, setUrl, setSuccess, setError }) => {
  const [fileName, setFileName] = useState(url ? "Product Image" : "");

  const onError = (error) => {
    setError(error);
  };

  const onComplete = (link) => {
    setUrl(link);
    setSuccess("File uploaded successfully");
  };

  const { upload, uploading, totalBytes, progress } = useUploadWithProgress(
    onError,
    onComplete
  );

  useEffect(() => {
    console.log(
      `Upolading: ${false} Progress: ${progress} Total: ${totalBytes}`
    );
  }, [uploading, progress, totalBytes]);

  const handleChange = (file) => {
    const epochTime = new Date().getTime();
    setFileName(file.name);
    let path = epochTime + "_" + file.name;
    upload(file, path);
  };

  const reset = () => {
    setSuccess("");
    setFileName("");
  };

  useEffect(() => {
    return reset;
  }, []);

  return (
    <Container>
      {!fileName && (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          disabled={fileName}
        >
          <>
            <input
              accept=".jpg,.png,.jpeg"
              type="file"
              name="file"
              disabled={fileName}
              style={{ visibility: "hidden" }}
            />
            <label htmlFor="file" className="fileSelect">
              <img src={galleryIcon} />

              <span>{"Upload or drag and \n drop an Image"}</span>

              <span className="small">
                Supports JPEG, JPEG2000, PNG up to 5MB
              </span>
            </label>
          </>
        </FileUploader>
      )}

      {fileName && (
        <div className="fileUpload">
          {uploading || !url ? (
            <CircularProgress progress={progress} />
          ) : (
            <img className="uploadedImage" src={url} alt="uploadedImg" />
          )}

          {uploading ? (
            <div className="uploadData">
              <span>{fileName || "FILENAME"}</span>
              <span>{`Uploading...${Math.round(progress)}% of ${Math.round(
                totalBytes / 1000
              )}KB`}</span>
            </div>
          ) : (
            <div className="uploadData">
              <span>{fileName}</span>
              <span
                className="change"
                onClick={() => {
                  setUrl(null);
                  setFileName("");
                }}
              >
                Change
              </span>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

const ProgressContainer = styled.div`
  height: 38px;
  width: 38px;
`;

const CircularProgress = ({ progress = 0 }) => {
  return (
    <ProgressContainer>
      <CircularProgressbar
        value={Math.round(progress)}
        styles={buildStyles({
          pathColor: "#55B653",
        })}
      />
    </ProgressContainer>
  );
};
