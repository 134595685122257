import styled from "styled-components";
import requestImage1 from "assets/png/requestImage1.png";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f4f5f6;

  padding: 2rem;
  background-color: ${(props) => (props.active ? "#F8F9FA" : "#ffffff")};

  img {
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 25px;
  }

  div.details {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    span {
      display: block;
      font-size: 1.4rem;
      color: #1c344f;
      font-weight: 500;
    }
  }

  div.time {
    margin-left: auto;
    color: #313840;
  }
`;

const RequestItem = ({ req, activeRequest, setActiveRequest }) => {
  const getTime = () => {
    if (!req) return "";

    let date = null;

    if (typeof req.request_date === "string") {
      date = new Date();
    } else {
      date = new Date(req.request_date._seconds * 1000);
    }

    console.log("Date", req.request_date);

    let hour = date.getHours();
    if (hour < 10) hour = `0${hour}`;

    let min = date.getMinutes();
    if (min < 10) min = `0${min}`;

    return `${hour}:${min}`;
  };

  const handleClick = () => {
    setActiveRequest(req);
  };

  return (
    <Container active={activeRequest?.id === req.id} onClick={handleClick}>
      <img src={req.user.avatar || requestImage1} />
      <div className="details">
        <span>
          {req.user.firstname} {req.user.lastname}
        </span>
      </div>

      <div className="time">{getTime()}</div>
    </Container>
  );
};

export default RequestItem;
