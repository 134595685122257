import styled from "styled-components";

import logo from "assets/svg/new-logo.svg";
import dashboard from "assets/svg/dashboard.svg";
import dashboardActive from "assets/svg/dashboard-active.svg";
import user from "assets/svg/user.svg";
import userActive from "assets/svg/user-active.svg";
import admin from "assets/svg/admin.svg";
import adminActive from "assets/svg/admin-active.svg";
import client from "assets/svg/client.svg";
import clientActive from "assets/svg/client-active.svg";
import recycle from "assets/svg/recycle.svg";
import recycleActive from "assets/svg/recycle-active.svg";
import product from "assets/svg/product.svg";
import productActive from "assets/svg/product-active.svg";
import settings from "assets/svg/settings.svg";
import settingsActive from "assets/svg/settings-active.svg";
import logout from "assets/svg/logout.svg";
import messageIcon from "assets/svg/message.svg";

import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "utils/routes";
import { Toggler } from "./toggler.component";
import { useContext, useEffect, useState } from "react";
import { getToken, logOut } from "utils/firebase";
import { AppContext } from "context/app.provider";
import { axiosInstance } from "utils/api";

const Container = styled.div`
  nav {
    display: flex;
    //justify-content: stretch;
  }

  section.left {
    width: 25%;
    height: 100vh;
    padding: 2rem;
    padding-top: 4rem;
    border-right: 1px solid #e8ebed;
    display: flex;
    flex-direction: column;
    header {
      margin-bottom: 5rem;
      img {
        width: 80%;
        display: block;
      }
    }
    ul {
      height: 100%;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div.spacer {
        flex: 1;
      }
      li {
        margin-bottom: 1rem;
        &:last-child {
          background: #e8ebed;
          border-radius: 4px;
          margin-bottom: 0;
        }

        button.logout {
          background: #f4f5f6;
          width: 100%;
        }

        a,
        button {
          padding: 1rem 2rem;
          display: flex;
          align-items: center;
          text-decoration: none;
          border: none;
          border-radius: 4px;
          // width: 100%;

          img {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 1.2rem;
          }
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #47525c;
          }
          &.active {
            background: #f1f9f1;
            border-radius: 4px;
            span {
              color: #224f21;
            }
          }
        }
      }
    }
  }

  section.right {
    width: 75%;
    height: 100vh;
    overflow-y: auto;
    position: relative;

    & > div.top {
      display: flex;
      align-items: center;
      padding: 2rem;
      border-bottom: 1px solid #e8ebed;
      justify-content: flex-end;
      max-height: 80px;
      background: #ffffff;
      position: sticky;
      top: 0;

      em {
        display: inline-block;
        width: 50px;
        margin-left: 0.8rem;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #1c344f;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    section.right {
      width: 78%;
    }
    section.left {
      width: 22%;
      header {
        img {
          width: 154px;
        }
      }
    }
  }
`;

export const DesktopNavbar = ({ Outlet }) => {
  const [isNotificationActive, setNotificationActive] = useState(false);
  const location = useLocation();

  const handleStatusToggle = async (_) => {
    try {
      let accessToken = await getToken();
      if (!accessToken) return;

      const response = await axiosInstance.post(
        "/user/availability",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setNotificationActive(response.data.data.newStatus == "online");
    } catch (error) {}
  };

  const { currentUser } = useContext(AppContext);

  useEffect(() => {
    if (currentUser) {
      setNotificationActive(currentUser.status == "online");
    }
  }, [currentUser]);

  return (
    <Container>
      <nav>
        <section className="left">
          <header>
            <img src={logo} alt="Recyclo logo" />
          </header>
          <ul>
            <li>
              <NavLink to={ROUTES.home}>
                <img
                  src={
                    location.pathname === ROUTES.home
                      ? dashboardActive
                      : dashboard
                  }
                  alt=""
                />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.user}>
                <img
                  src={location.pathname === ROUTES.user ? userActive : user}
                  alt=""
                />
                <span>User List</span>
              </NavLink>
            </li>
            {currentUser?.access_level?.toLowerCase() === "super" && (
              <li>
                <NavLink to={ROUTES.admin}>
                  <img
                    src={
                      location.pathname === ROUTES.admin ? adminActive : admin
                    }
                    alt=""
                  />
                  <span>Admin List</span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to={ROUTES.client}>
                <img
                  src={
                    location.pathname === ROUTES.client ? clientActive : client
                  }
                  alt=""
                />
                <span>Client List</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.recycle}>
                <img
                  src={
                    location.pathname === ROUTES.recycle
                      ? recycleActive
                      : recycle
                  }
                  alt=""
                />
                <span>Recycle</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.products}>
                <img
                  src={
                    location.pathname === ROUTES.products
                      ? productActive
                      : product
                  }
                  alt=""
                />
                <span>Products</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.liveChat}>
                <img
                  src={
                    location.pathname === ROUTES.liveChat
                      ? messageIcon
                      : messageIcon
                  }
                  alt=""
                />
                <span>Live Chat</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.settings}>
                <img
                  src={
                    location.pathname === ROUTES.settings
                      ? settingsActive
                      : settings
                  }
                  alt=""
                />
                <span>Settings</span>
              </NavLink>
            </li>
            <div className="spacer" />
            <li>
              <button
                className="logout"
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                <img src={logout} alt="" />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </section>
        <section className="right">
          <div className="top">
            {/* <img src={messageIcon} alt="" /> */}
            {/* <img src={notificationIcon} alt="" /> */}
            <Toggler
              active={isNotificationActive}
              setActive={handleStatusToggle}
            />
            <em>{isNotificationActive ? "Online" : "Offline"}</em>
          </div>
          <Outlet />
        </section>
      </nav>
    </Container>
  );
};
