import React from "react";
import { useState } from "react";
import styled from "styled-components";
import cancelIcon from "assets/svg/cancel.svg";
import InputComponent from "components/input.component";
import { RequestProductClass } from "utils/shared";
import { MiniLoader } from "components/loader.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    border-bottom: 1px solid #ebedf0;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      color: #1c344f;
    }
    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  form {
    padding: 2rem 2.5rem;
    label {
      display: block;
      margin-bottom: 3rem;
      span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 8px;
      }
    }
    button {
      display: block;
      margin-left: auto;
      font-weight: 400;
      font-size: 14px;
      height: 56px;
      color: #ffffff;
      background: #55b653;
      border-radius: 8px;
      border: none;
      padding: 12px 16px;
      min-width: 105px;
    }
  }
`;

const UnitContainer = styled.div`
  font-size: 16px;
  color: #47525c;
`;

const CompleteRequest = ({
  closeOverlay,
  completeRequest,
  originalQuantity,
  productClass,
}) => {
  const [quantity, setQuantity] = useState(originalQuantity);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    await completeRequest(quantity);
    setLoading(false);
  };

  return (
    <Container>
      <header>
        <h1>Quantity</h1>
        <button onClick={closeOverlay}>
          <img src={cancelIcon} alt="" />
        </button>
      </header>

      <div style={{ height: 24 }} />

      <form id="completeRequestForm" onSubmit={handleSubmit}>
        <label htmlFor="quantity">
          <InputComponent
            id="quantity"
            placeholder="Enter quantity"
            type="text"
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
            suffixIcon={
              <UnitContainer>
                {productClass === RequestProductClass.Specific ? "Units" : "Kg"}
              </UnitContainer>
            }
            required
          />
        </label>

        <div style={{ height: 24 }} />

        <button type="submit" form="completeRequestForm" disabled={loading}>
          {loading ? <MiniLoader /> : "Continue"}
        </button>
      </form>
    </Container>
  );
};

export default CompleteRequest;
