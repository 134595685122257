import styled from "styled-components";
import { Card } from "../components/card.component";
import { RequestCard } from "../components/request-card.component";
import arrowUpRightIcon from "./../assets/svg/arrow-up-right.svg";
import { useContext, useEffect, useState } from "react";
import { RequestTable } from "components/tables/request/request-table.component";
import {
  RequestAction,
  RequestRow,
} from "components/tables/request/request.row.component";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";
import { Link, Navigate } from "react-router-dom";
import { ROUTES } from "utils/routes";
import { AppContext } from "context/app.provider";
import Loader2 from "components/loader-2";
import { Tabs } from "./recycle.page";
import { Toast, ToastStatus } from "components/toast.component";

const Container = styled.div`
  div.home-content {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;

    section.hero {
      padding-left: 2.5rem;
      margin-top: 4rem;
      margin-bottom: 3.2rem;

      h1 {
        color: #1c344f;
        margin-bottom: 8px;
        span {
          font-size: 1.8rem;
          line-height: 3.2rem;
          &:first-child {
            font-weight: normal;
          }
        }
      }

      h2 {
        font-size: 1.4rem;
        font-weight: normal;
        color: #919eab;
      }
    }

    section.cards {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      margin-bottom: 4rem;
      display: grid;
      grid-template-columns: auto;
      gap: 15px;
      width: 100%;
    }

    section.requests {
      header {
        padding: 1.6rem 2.5rem;
        border-bottom: 1px solid #dce2e6;
        display: flex;
        justify-content: space-between;

        div.left {
          h2 {
            font-size: 1.6rem;
            font-weight: normal;
            display: inline-block;
            margin-right: 8px;
            color: #1c344f;
          }
          div {
            background: #7ac678;
            border-radius: 24px;
            color: #fff;
            display: inline-block;
            padding: 0 1.5rem;
            font-size: 14px;
            border-radius: 1rem;
          }
        }
        a {
          background: transparent;
          text-decoration: none;
          border: none;
          font-weight: normal;
          font-size: 16px;
          color: #6955eb;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
      div.table-container {
        display: none;
      }
    }
  }

  @media screen and (min-width: 600px) {
    div.home-content {
      section.cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (min-width: 900px) {
    div.home-content {
      section.cards {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    div.home-content {
      section.cards {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    div.home-content {
      section.hero {
        h1 {
          span {
            font-size: 32px;
          }
        }
        h2 {
          font-size: 16px;
        }
      }

      section.requests {
        flex: 1;

        header {
          border: none;
          div.left {
            h2 {
              font-size: 20px;
            }
          }
          button {
            font-size: 16px;
          }
        }

        div.container {
          display: none;
        }

        div.table-container {
          display: block;
          padding: 0 2.5rem;
          height: 100%;
        }
      }
    }
  }
`;

export const HomePage = () => {
  const {
    currentUser,
    initializedLists,
    initializeLists,
    users,
    admins,
    clients,
    requests,
    setRequests,
    newRequests,
    chatRequests,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [statusMessage, setStatusMessage] = useState("");

  const fetchData = async () => {
    try {
      let accessToken = await getToken();

      if (!accessToken) return;

      setStatusMessage("Fetching admins...");

      let response = await axiosInstance.get("/admin", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const admins = response.data.data;

      setStatusMessage("Fetching users...");

      response = await axiosInstance.get("/admin/user", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const users = response.data.data;

      setStatusMessage("Fetching clients...");

      response = await axiosInstance.get("/admin/client", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const clients = response.data.data;

      setStatusMessage("Fetching pick-up requests...");

      response = await axiosInstance.get("/admin/pickup-request", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const requests = response.data.data;

      setStatusMessage("Fetching pick-up requests...");

      response = await axiosInstance.get("/admin/product", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const products = response.data.data;

      setStatusMessage("Fetching chat requests...");

      response = await axiosInstance.get("/chat-request", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const chat_requests = response.data.data;

      initializeLists(
        admins,
        users,
        clients,
        requests,
        products,
        chat_requests
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (typeof initializedLists === "boolean") {
      if (initializedLists === false) {
        fetchData();
      } else {
        setLoading(false);
      }
    }
  }, [initializedLists]);

  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState(ToastStatus.failed);

  const openToast = (msg, status = ToastStatus.failed) => {
    setToastStatus(status);
    setToastMsg(msg);
  };

  if (!currentUser) {
    return <Navigate to={ROUTES.login} />;
  }

  const changeRequestStatus = async ({
    request_id,
    status,
    quantity = null,
  }) => {
    try {
      let accessToken = await getToken();

      if (!accessToken) return;

      const res = await axiosInstance.patch(
        "/admin/pickup-request",
        {
          request_id,
          status,
          quantity: Number(quantity),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const result = res.data;

      if (result.success) {
        openToast(result.message, ToastStatus.success);

        setRequests((prev) =>
          prev.map((item) => {
            if (item.id === request_id) {
              return { ...item, status };
            }

            return item;
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        openToast(error.response.data.message);
      }
    }
  };

  return (
    <Container>
      {toastMsg && (
        <Toast
          text={toastMsg}
          timeout={3000}
          toastStatus={toastStatus}
          width="400px"
        />
      )}

      {loading ? (
        <Loader2 message={statusMessage} />
      ) : (
        <div className="home-content">
          <section className="hero">
            <h1>
              <span>Welcome back, </span>
              <span>{currentUser.name}</span>
            </h1>
            <h2>Here's an overview of your account</h2>
          </section>

          <section className="cards">
            <Card
              info="Total No. of Users"
              stat={users.length}
              infoColor="#327330"
              statColor="#1C344F"
              bgColor="#EDF7ED"
            />
            <Card
              info="Total No. of Admins"
              stat={admins.length}
              infoColor="#231297"
              statColor="#1C344F"
              bgColor="#E3DFFB"
            />
            <Card
              info="Total No. of Clients"
              stat={clients.length}
              infoColor="#F9991F"
              statColor="#1C344F"
              bgColor="#FFF5F2"
            />
            <Card
              info="Pending Chat Requests"
              stat={chatRequests.length}
              infoColor="#1C344F"
              statColor="#1C344F"
              bgColor="#D5E7FA"
            />
          </section>

          <section className="requests">
            <header>
              <div className="left">
                <h2>New Pick Requests</h2>
                <div>{newRequests}</div>
              </div>
              <Link to={ROUTES.recycle}>
                <span>View All</span>
                <img src={arrowUpRightIcon} alt="" />
              </Link>
            </header>

            <div className="container">
              {requests
                .filter((item) => item.status?.toLowerCase() === "pending")
                .slice(0, 3)
                .map((request) => (
                  <RequestCard
                    key={"top-three-" + request.id}
                    id={request.id}
                    name={`${request.requested_by.firstname} ${request.requested_by.lastname}`}
                    requestName={request.product.product_name}
                    date={new Date(
                      request.created_at._seconds * 1000
                    ).toDateString()}
                    quantity={request.quantity}
                    requestImage={request.requested_by.avatar}
                    number={request.requested_by.phonenumber}
                    status={request.status}
                    address={
                      request.address
                        ? `${request.address.address_line1}, ${request.address.city}, ${request.address.country}`
                        : ""
                    }
                    changeRequestStatus={changeRequestStatus}
                    openToast={openToast}
                  />
                ))}
            </div>

            <div className="table-container">
              <RequestTable>
                {requests
                  .filter((item) => item.status?.toLowerCase() === "pending")
                  .slice(0, 3)
                  .map((request) => (
                    <RequestRow
                      key={"top-three-" + request.id}
                      id={request.id}
                      name={`${request.requested_by.firstname} ${request.requested_by.lastname}`}
                      type={request.product.product_name}
                      quantity={request.quantity}
                      status={request.status}
                      address={
                        request.address
                          ? `${request.address.address_line1}, ${request.address.city}, ${request.address.country}`
                          : ""
                      }
                      initiationTime={new Date(
                        request.created_at._seconds * 1000
                      ).toDateString()}
                      userImage={request.requested_by.avatar}
                      phoneNo={request.requested_by.phonenumber}
                      currentTab={Tabs.Pending}
                      changeRequestStatus={changeRequestStatus}
                      openToast={openToast}
                      actions={[RequestAction.Accept, RequestAction.Decline]}
                    />
                  ))}
              </RequestTable>
            </div>
          </section>
        </div>
      )}
    </Container>
  );
};
