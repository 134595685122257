import React, { createContext, useState } from "react";

export const AppContext = createContext({
  initializedLists: false,
  currentUser: null,
  setCurrentUser: () => null,
  admins: [],
  setAdmins: (admins) => [admins],
  users: [],
  setUsers: (users) => [users],
  clients: [],
  setClients: (clients) => [clients],
  requests: [],
  setRequests: (requests) => [requests],
  products: [],
  setProducts: (products) => [products],
  initializeLists: (admins, users, clients, chatRequests) => [
    admins,
    users,
    clients,
    chatRequests,
  ],
  reset: () => null,
  newRequests: 0,
  setNewRequests: (count) => count,
  chatRequests: [],
  setChatRequests: (chatRequests) => [chatRequests],
  pendingLoginData: null,
  setPendingLoginData: (data) => data,
});

const AppProvider = ({ children }) => {
  const [currentUser, setUser] = useState(null);
  const [initializedLists, setInitializedLists] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [requests, setRequests] = useState([]);
  const [products, setProducts] = useState([]);
  const [chatRequests, setChatRequests] = useState([]);
  const [pendingLoginData, setPendingLoginData] = useState(null);

  const [newRequests, setNewRequests] = useState(0);

  const setCurrentUser = (user) => {
    if (!user) {
      return setUser(null);
    }

    setUser(user);
  };

  const initializeLists = (
    admins,
    users,
    clients,
    requests,
    products,
    chatRequests
  ) => {
    if (initializedLists) return;

    setUsers(users);
    setAdmins(admins);
    setClients(clients);
    setRequests(requests);
    setProducts(products);
    setChatRequests(chatRequests);
    setInitializedLists(true);
  };

  const reset = () => {
    setUsers([]);
    setAdmins([]);
    setClients([]);
    setRequests([]);
    setProducts([]);
    setChatRequests([]);
    setNewRequests(0);
    setPendingLoginData(null);
    setInitializedLists(false);
  };

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        initializedLists,
        clients,
        setClients,
        users,
        setUsers,
        admins,
        setAdmins,
        products,
        setProducts,
        initializeLists,
        requests,
        setRequests,
        newRequests,
        setNewRequests,
        reset,
        chatRequests,
        setChatRequests,
        pendingLoginData,
        setPendingLoginData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
