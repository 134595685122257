import styled from "styled-components";
import ellipseDivideIcon from "assets/svg/ellipse-divide.svg";
import DropdownMenu from "./dropdown-menu.component";
import { useEffect, useState } from "react";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";

const Container = styled.div`
  padding: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dce2e6;
  button {
    border: none;
    background: transparent;
    img {
      width: 15px;
      height: 15px;
    }
  }
  div.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    align-self: stretch;
    div.top {
      margin-bottom: 2rem;
      em {
        &:first-child {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #1c344f;
          display: inline-block;
          margin-right: 2.8rem;
        }
        &:last-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          border-radius: 60px;
          padding: 4px 12px;
          &.online {
            color: #6955eb;
            background: #f7f6fe;
          }
          &.offline {
            color: #db6b5d;
            background: #fdf8f7;
          }
        }
      }
    }
    div.bottom {
      em {
        display: flex;
        align-items: center;
        max-width: 245px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        font-style: normal;
        span {
          font-weight: 400;
          font-size: 12px;
          color: #748595;
          &:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        img {
          width: 4px;
          height: 4px;
          margin: 0 8px;
          display: inline-block;
        }
      }
    }
  }
`;

export const AdminCard = ({
  id,
  name,
  status,
  number,
  email,
  enabledStatus,
  editAdmin,
  setSuccess,
  setError,
  deleteAdmin,
}) => {
  const [isLoading, setLoading] = useState(false);

  const changeAdminStatus = async (newStatus) => {
    setError("");
    setLoading(true);

    const user = {
      id,
      enabled: newStatus,
    };

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.patch("/admin/enabled-status", user, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);

      if (response.status === 200) {
        editAdmin(id, newStatus);
        setSuccess(`The Admin is now ${newStatus ? "enabled" : "disabled"}`);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);

      console.log(e);
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const actions = [];

    if (enabledStatus) {
      actions.push("Disable");
    } else {
      actions.push("Enable");
    }

    actions.push("Remove");

    setOptions(actions);
  }, [enabledStatus]);

  const handleOptionSelected = (option) => {
    if (isLoading) return;

    if (option != "Remove") {
      if (option === "Disable") {
        changeAdminStatus(false);
      } else {
        changeAdminStatus(true);
      }
    } else {
      deleteAdmin(id, "admin");
    }
  };

  return (
    <Container>
      <div className="wrapper">
        <div className="top">
          <em>{name}</em>
          <em className={`${status ? "online" : "offline"}`}>
            {status ? "Online" : "Offline"}
          </em>
        </div>
        <div className="bottom">
          <em>
            <span>{number}</span>
            <img src={ellipseDivideIcon} alt="" />
            <span>{email}</span>
          </em>
        </div>
      </div>

      <DropdownMenu options={options} onSelected={handleOptionSelected} />
    </Container>
  );
};
