import styled from "styled-components";

import crownIcon from "assets/svg/crown.svg";
import { useEffect, useState } from "react";
import { getToken } from "utils/firebase";
import { axiosInstance } from "utils/api";
import DropdownMenu from "components/dropdown-menu.component";

const TableRow = styled.tr`
  div.wrapper {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      margin-right: 1rem;
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
    div.side {
      display: flex;
      flex-direction: column;
      em {
        font-style: normal;
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #1c344f;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:last-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
        }
      }
    }
  }
  td.points {
    em {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1c344f;
      display: inline-block;
      margin-right: 8px;
      width: 90px;
      padding: 1rem;
      border-radius: 60px;
      background: #fef4e6;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
  }
  td.status {
    em {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      border-radius: 60px;
      padding: 1rem;
      &.enabled {
        color: #71bf5f;
        background: #ebf6e9;
      }
      &.disabled {
        color: #db6b5d;
        background: #fdf8f7;
      }
    }
  }
  td.action {
    button {
      display: flex;
      align-items: center;
      &.disable {
        background: #c44931;
      }
      &.enable {
        background: #3a9428;
      }

      border-radius: 6px;
      border: none;
      padding: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
  }
`;

export const UserRow = ({
  id,
  name,
  emailAddress,
  phoneNo,
  pointsPerMin,
  status,
  address,
  userImage,
  setError,
  setSuccess,
  customSetTimeout,
  editUser,
  showPointsInfo,
  setPointsInfo,
  deleteUser,
  user,
}) => {
  const [isLoading, setLoading] = useState(false);

  const changeUserStatus = async (newStatus) => {
    setError("");
    setLoading(true);
    const user = {
      id,
      status: newStatus ? "enabled" : "disabled",
    };
    let accessToken = await getToken();
    if (!accessToken) return;
    try {
      let response = await axiosInstance.patch("/admin/user", user, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setLoading(false);
      console.log(response);
      if (response.status === 200) {
        editUser(id, newStatus ? "enabled" : "disabled");
        setSuccess(`The User is now ${newStatus ? "enabled" : "disabled"}`);
        // customSetTimeout(() => {
        //   setSuccess("");
        // });
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      customSetTimeout(() => {
        setError("");
      });

      console.log(e);
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const actions = [];

    if (status) {
      actions.push("Disable");
    } else {
      actions.push("Enable");
    }

    actions.push("Remove");

    setOptions(actions);
  }, [status]);

  const handleOptionSelected = (option) => {
    if (isLoading) return;

    if (option != "Remove") {
      if (option === "Disable") {
        changeUserStatus(false);
      } else {
        changeUserStatus(true);
      }
    } else {
      deleteUser(id, "user");
    }
  };

  return (
    <>
      <TableRow>
        <td>
          <div className="wrapper">
            <img src={userImage} alt="user" />
            <div className="side">
              <em>{name}</em>
              <em>{phoneNo}</em>
            </div>
          </div>
        </td>
        <td>{emailAddress}</td>
        <td>{address}</td>
        <td className="points">
          <em
            onClick={() => {
              setPointsInfo({
                pickupPoints: user.points,
                referralPoints: user.referral_points,
              });
              showPointsInfo();
            }}
          >
            <img src={crownIcon} alt="" />
            <span>{pointsPerMin}</span>
          </em>
        </td>
        <td className="status">
          <em className={`${status ? "enabled" : "disabled"}`}>
            {status ? "Enabled" : "Disabled"}
          </em>
        </td>
        <td className="action">
          <DropdownMenu options={options} onSelected={handleOptionSelected} />
        </td>
      </TableRow>
    </>
  );
};
