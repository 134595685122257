export const ROUTES = {
  home: "/",
  admin: "/admin",
  user: "/user",
  client: "/client",
  recycle: "/recycle",
  settings: "/settings",
  login: "/login",
  products: "/products",
  liveChat: "/live-chat",
  forgetPassword: "/forget-password",
};
