import styled from "styled-components";
import search from "assets/svg/search.svg";
import plus from "assets/svg/plus.svg";
import { ProductRow } from "./product.row.component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import { usePagination } from "utils/shared";
import { useEffect, useState } from "react";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;
  }

  div.table-wrapper {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      //background-color: red;
      border-collapse: collapse;
      tr {
        border-bottom: 1px solid #e8ebed;
        width: 100%;
      }

      td,
      th {
        &:last-child {
          padding-right: 2rem;
        }
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #1c344f;
        button {
          background: transparent;
          border: none;
          img {
            width: 20px;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;

        tr {
          background-color: #f9fafb;
          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    header {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;

        color: #061927;
      }
      div.right {
        display: flex;
        align-items: center;
        div.input-wrapper {
          width: 360px;
          margin-right: 1.6rem;
          background: #e8ebed;
          border-radius: 12px;
          display: flex;
          height: 56px;
          flex-direction: row;
          align-items: center;
          padding: 1.8rem;
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
          }
          input {
            display: inline-block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            background: transparent;
            border: none;
            width: 250px;
            &:focus {
              outline: none;
            }
          }
        }
        button:last-child {
          display: flex;
          align-items: center;
          background: #55b653;
          border-radius: 12px;
          border: none;
          height: 56px;
          padding: 1.8rem;
          margin-left: 1.6rem;
          margin-right: 1.6rem;
          width: 230px;

          img {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 1.6rem;
          }
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }
`;

export const ProductTable = ({
  products,
  openOverlay,
  openEditOverlay,
  openDeleteOverlay,
}) => {
  const [filtered, setFiltered] = useState(products);

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredProducts = () => {
    const result = products.filter((product) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(product.product_class.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredProducts();
    } else {
      setFiltered(products);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredProducts();
      } else {
        setFiltered(products);
      }
    } else {
      const result = products.filter((product) => {
        if (product.product_name?.toLowerCase()?.includes(query)) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(product.product_class.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      <header>
        <h1>Product</h1>
        <div className="right">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>
          <FilterButton
            page={"product"}
            options={["General", "Specific"]}
            onSelect={onFilterSelected}
          />
          <button onClick={openOverlay}>
            <img src={plus} alt="" />
            <span>Add New Product</span>
          </button>
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="product"
        />
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>product ID</th>
              <th>product name</th>
              <th>product class</th>
              <th>min quantity (KG / units)</th>
              <th>points per minimum KG / units</th>
              <th>options</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((product) => (
              <ProductRow
                key={product.id}
                productId={product.id}
                productName={product.product_name}
                productClass={product.product_class}
                minQuantity={`${
                  product.min_quantity
                } ${product.unit.toUpperCase()}`}
                pointsPerMin={product.points_per_unit}
                openEditOverlay={() => openEditOverlay(product)}
                openDeleteOverlay={openDeleteOverlay}
              />
            ))}
          </tbody>
        </table>
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={products.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};
