import axios from "axios";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL:
    // process.env.NODE_ENV === "production"
    // ?
    "https://recyclo.onrender.com/api/v1",
  // :
  // "https://localhost:4000/api/v1",
});
