import React, { useRef } from "react";
import filter from "assets/svg/filter.svg";
import styled from "styled-components";
import Popup from "reactjs-popup";

const Button = styled.button`
  display: flex;
  align-items: center;
  background: #55b653;
  border-radius: 12px;
  border: none;
  width: unset;
  height: 56px;
  padding: 1.8rem;

  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 0;
  }

  span {
    display: none;
  }

  @media screen and (min-width: 900px) {
    width: 140px;
    span {
      display: inline-block;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }

    img {
      margin-right: 1.6rem;
    }
  }
`;

const Option = styled.div`
  padding: 1rem;
  cursor: pointer;
  color: #1c344f;
  font-size: 1.6rem;

  &:hover {
    background: #ccc;
  }
`;

function FilterButton({ page, options = [], onSelect }) {
  const ref = useRef(null);

  const closeTooltip = () => ref.current?.close();

  const handleClick = (value) => () => {
    closeTooltip();

    onSelect(value);
  };

  return (
    <Popup
      ref={ref}
      position={"bottom center"}
      closeOnDocumentClick
      trigger={
        <Button>
          <img src={filter} alt="" />
          <span>Filters</span>
        </Button>
      }
    >
      {options.map((option, idx) => (
        <Option key={`${page}-option-${idx}`} onClick={handleClick(option)}>
          {option}
        </Option>
      ))}
    </Popup>
  );
}

export default FilterButton;
