import styled from "styled-components";
import search from "assets/svg/search.svg";
import plusIcon from "assets/svg/plus.svg";
import { AdminRow } from "./admin.row.component";
import { useEffect, useState } from "react";
import { Toast, ToastStatus } from "components/toast.component";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;
  }

  div.table-wrapper {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid #e8ebed;
        width: 100%;
      }

      td,
      th {
        &:last-child {
          padding-right: 2rem;
        }
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #1c344f;
        button {
          background: transparent;
          border: none;
          img {
            width: 20px;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;

        tr {
          background-color: #f4f5f6;
          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 3.6rem;

        color: #061927;
      }

      div.right {
        display: flex;
        align-items: center;
        div.input-wrapper {
          width: 360px;
          margin-right: 1.6rem;
          background: #e8ebed;
          box-sizing: border-box;
          height: 56px;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.8rem;

          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
          }
          input {
            display: inline-block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            background: transparent;
            border: none;
            width: 330px;
            &:focus {
              outline: none;
            }
          }
        }
        button:last-child {
          display: flex;
          align-items: center;
          background: #55b653;
          border-radius: 12px;
          border: none;
          height: 56px;
          padding: 1.8rem;
          margin-left: 1.6rem;
          margin-right: 1.6rem;
          width: 240px;

          img {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 1.6rem;
          }
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }
`;

export const AdminTable = ({ admins, openOverlay, editAdmin, deleteAdmin }) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [filtered, setFiltered] = useState(admins);

  useEffect(() => {
    setFiltered(admins);
  }, [admins]);

  const customSetTimeout = (timeoutCallback) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let id = setTimeout(timeoutCallback, 4000);
    setTimeoutId(id);
  };

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredAdmin = () => {
    const result = admins.filter((admin) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(admin.status.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredAdmin();
    } else {
      setFiltered(admins);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredAdmin();
      } else {
        setFiltered(admins);
      }
    } else {
      const result = admins.filter((admin) => {
        const fullname = admin.firstname + " " + admin.lastname;

        const phoneNo = getUserPhoneNumber(admin);

        if (
          admin.email.toLowerCase().includes(query) ||
          admin.firstname?.toLowerCase()?.includes(query) ||
          admin.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(admin.status.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}
      <header>
        <h1>Admin List</h1>
        <div className="right">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>
          <FilterButton
            page={"admin"}
            options={["Online", "Offline"]}
            onSelect={onFilterSelected}
          />
          <button onClick={openOverlay}>
            <img src={plusIcon} alt="" />
            <span>Create New Admin</span>
          </button>
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="admin"
        />
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>email address</th>
              <th>status</th>
              <th>actions</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((admin) => (
              <AdminRow
                key={admin.id}
                id={admin.id}
                name={`${admin.firstname} ${admin.lastname}`}
                status={admin.status === "online"}
                emailAddress={admin.email}
                phoneNo={getUserPhoneNumber(admin)}
                enabledStatus={admin.enabled}
                setError={setError}
                setSuccess={setSuccess}
                customSetTimeout={customSetTimeout}
                editAdmin={editAdmin}
                deleteAdmin={deleteAdmin}
              />
            ))}
          </tbody>
        </table>
      </div>

      {currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={admins.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};
