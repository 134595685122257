const Loader2 = (props) => {
  return (
    <div
      id="fullscreen-custom-spinner"
      className="fullscreen-custom-spinner-container"
    >
      <span className="init-loader"></span>
      <div id="load-status" className="load-status show-load-status">
        {props.message && props.message}
      </div>
    </div>
  );
};

export default Loader2;
