import InputComponent from "components/input.component";
import { MiniLoader } from "components/loader.component";
import PhoneInputComponent from "components/phone-input.component";
import { AppContext } from "context/app.provider";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { axiosInstance } from "utils/api";
import { getToken } from "utils/firebase";
import { ROUTES } from "utils/routes";

const Form = styled.form`
  label {
    display: block;
    margin-bottom: 3rem;

    span {
      font-size: 1.4rem;
      color: #1c344f;
      display: block;
      margin-bottom: 1.6rem;
    }
  }
  button {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    background: #55b653;
    border-radius: 8px;
    border: none;
    height: 56px;
    padding: 1rem 3rem;
  }
`;

export const SettingsForm = ({ setError, setSuccess }) => {
  const { currentUser, setCurrentUser } = useContext(AppContext);

  console.log("Current user", currentUser);

  const [firstName, setFirstName] = useState(currentUser?.firstname || "");
  const [lastName, setLastName] = useState(currentUser?.lastname || "");

  const [phoneNo, setPhoneNo] = useState(currentUser?.phonenumber || "");
  const [dialCode, setDialCode] = useState(currentUser?.dialCode || "");

  const [loading, setLoading] = useState(false);

  if (!currentUser) return <Navigate to={ROUTES.login} />;

  const updateUserInfo = async (e) => {
    e.preventDefault();

    setError("");
    setLoading(true);

    let newDetails = {};
    if (firstName) newDetails.firstname = firstName;
    if (lastName) newDetails.lastname = lastName;
    if (phoneNo) newDetails.phonenumber = phoneNo;
    if (dialCode) newDetails.dialCode = dialCode;

    let accessToken = await getToken();
    if (!accessToken) return;

    try {
      let response = await axiosInstance.patch("/admin", newDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);

      console.log(response);

      if (response.status === 200) {
        setSuccess("The User has been updated successfully");

        setCurrentUser((prev) => ({ ...prev, ...newDetails }));

        setTimeout(() => {
          setSuccess("");
        }, 4000);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  return (
    <Form>
      <label htmlFor="firstName">
        <span>FIRST NAME</span>
        <InputComponent
          id="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </label>
      <label htmlFor="lastName">
        <span>LAST NAME</span>
        <InputComponent
          id="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </label>
      <label htmlFor="email">
        <span>EMAIL ADDRESS</span>
        <InputComponent
          id="email"
          type="email"
          value={currentUser.email}
          disabled
        />
      </label>
      <label htmlFor="number">
        <span>PHONE NUMBER</span>
        <PhoneInputComponent
          id="number"
          onChange={(phone, country) => {
            setDialCode(country.dialCode);
            setPhoneNo(phone.slice(country.dialCode.length));
          }}
          value={dialCode + phoneNo}
          required
        />
      </label>
      <button onClick={updateUserInfo}>
        {loading ? <MiniLoader /> : "Save Changes"}
      </button>
    </Form>
  );
};
