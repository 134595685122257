import styled from "styled-components";
import search from "assets/svg/search.svg";
import { Tabs } from "pages/recycle.page";
import { usePagination } from "utils/shared";
import { useContext, useMemo, useState } from "react";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import { RequestAction, RequestRow } from "./request.row.component";
import { useEffect } from "react";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";
import { AppContext } from "context/app.provider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;
  }

  div.table-wrapper {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      //background-color: red;
      border-collapse: collapse;
      tr {
        border-bottom: 1px solid #e8ebed;
        width: 100%;
      }

      td,
      th {
        &:last-child {
          padding-right: 2rem;
        }
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #1c344f;
        button {
          background: transparent;
          border: none;
          img {
            width: 20px;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;

        tr {
          background-color: #f9fafb;
          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;

        color: #061927;
      }

      div.right {
        display: flex;
        align-items: center;

        div.input-wrapper {
          width: 360px;
          margin-right: 1.6rem;
          background: #e8ebed;
          box-sizing: border-box;
          height: 56px;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.8rem;

          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
          }

          input {
            display: inline-block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            background: transparent;
            border: none;
            width: 330px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    nav {
      margin: 2rem 0;
      margin-bottom: 3rem;

      button {
        background-color: transparent;
        border: none;
        margin-right: 2.4rem;
        font-weight: 400;
        font-size: 1.6rem;
        color: #919eab;

        &.active {
          color: #1c344f;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            background: #55b653;
            bottom: -5px;
            left: 0;
            width: 90%;
          }
        }
      }
    }
  }
`;

export const RequestTable = ({
  page,
  currentTab,
  setCurrentTab,
  openOverlay,
  openToast,
  changeRequestStatus,
  pendingRequests,
  ongoingRequests,
  historyRequests,
  children,
}) => {
  const currentTabRequests = useMemo(() => {
    if (currentTab === Tabs.Pending) {
      return pendingRequests;
    } else if (currentTab === Tabs.Ongoing) {
      return ongoingRequests;
    } else if (currentTab === Tabs.History) {
      return historyRequests;
    }

    return [];
  }, [currentTab, pendingRequests, ongoingRequests, historyRequests]);

  const [filtered, setFiltered] = useState(currentTabRequests);

  useEffect(() => {
    setFiltered(currentTabRequests);
  }, [currentTabRequests]);

  const { products } = useContext(AppContext);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
    reset,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredRequest = () => {
    const result = currentTabRequests.filter((request) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(request.product?.product_name?.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredRequest();
    } else {
      setFiltered(currentTabRequests);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredRequest();
      } else {
        setFiltered(currentTabRequests);
      }
    } else {
      const result = currentTabRequests.filter((request) => {
        const fullname =
          request.requested_by.firstname + " " + request.requested_by.lastname;

        const address = request.address
          ? `${request.address.address_line1}, ${request.address.city}, ${request.address.state}`
          : "";

        if (
          request.requested_by.firstname?.toLowerCase()?.includes(query) ||
          request.requested_by.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          address.toLowerCase().includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(request.product?.product_name?.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  useEffect(() => {
    if (currentTab) {
      reset();
      setActiveFilter([]);
    }
  }, [currentTab]);

  return (
    <Container>
      {page === "request" && (
        <>
          <header>
            <h1>Recycle</h1>
            <div className="right">
              <div className="input-wrapper">
                <img src={search} alt="" />
                <input
                  type="text"
                  placeholder="Search name"
                  onChange={onSearch}
                />
              </div>
              <FilterButton
                page={"recycle"}
                options={products.map((product) => product.product_name)}
                onSelect={onFilterSelected}
              />
            </div>
          </header>

          {activeFilter.length > 0 && (
            <ActiveFilter
              clearFilter={clearFilter}
              removeFilter={removeFilter}
              activeFilters={activeFilter}
              page="recycle"
            />
          )}

          <nav>
            <button
              className={currentTab === Tabs.Pending ? "active" : ""}
              onClick={() => setCurrentTab(Tabs.Pending)}
            >
              Pending
            </button>
            <button
              className={currentTab === Tabs.Ongoing ? "active" : ""}
              onClick={() => setCurrentTab(Tabs.Ongoing)}
            >
              Ongoing
            </button>
            <button
              className={currentTab === Tabs.History ? "active" : ""}
              onClick={() => setCurrentTab(Tabs.History)}
            >
              History
            </button>
          </nav>
        </>
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>garbage type</th>
              <th>quantity</th>
              <th>address</th>
              <th>
                {currentTab === Tabs.History
                  ? "pickup complete "
                  : "initiation "}
                date/time
              </th>
              <th>{currentTab === Tabs.History ? "status" : "actions "}</th>
            </tr>
          </thead>

          <tbody>
            {!children &&
              currentItems.map((request) => {
                const getActions = () => {
                  if (currentTab === Tabs.Pending) {
                    return [RequestAction.Accept, RequestAction.Decline];
                  } else if (currentTab === Tabs.Ongoing) {
                    return [RequestAction.Complete, RequestAction.Cancel];
                  }

                  return null;
                };

                return (
                  <RequestRow
                    key={request.id}
                    id={request.id}
                    name={`${request.requested_by.firstname} ${request.requested_by.lastname}`}
                    type={request.product.product_name}
                    quantity={request.quantity}
                    status={request.status}
                    address={
                      request.address
                        ? `${request.address.address_line1}, ${request.address.city}, ${request.address.country}`
                        : ""
                    }
                    initiationTime={new Date(
                      request.created_at._seconds * 1000
                    ).toDateString()}
                    userImage={request.requested_by.avatar}
                    phoneNo={request.requested_by.phonenumber}
                    currentTab={currentTab}
                    changeRequestStatus={changeRequestStatus}
                    openToast={openToast}
                    actions={getActions()}
                    openComplete={openOverlay}
                    productClass={request.product.product_class}
                  />
                );
              })}

            {children && children}
          </tbody>
        </table>
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={filtered.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};
