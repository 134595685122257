import styled from "styled-components";
import DropdownMenu from "components/dropdown-menu.component";

const TableRow = styled.tr`
  div.wrapper {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      margin-right: 1rem;
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
    div.side {
      display: flex;
      flex-direction: column;
      em {
        font-style: normal;
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #1c344f;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:last-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
        }
      }
    }
  }
  td.points {
    em {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1c344f;
      display: inline-block;
      margin-right: 8px;
      width: 70px;
      padding: 1rem;
      border-radius: 60px;
      background: #fef4e6;
      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
  }
  td.status {
    em {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      border-radius: 60px;
      padding: 1rem;
      &.enabled {
        color: #71bf5f;
        background: #ebf6e9;
      }
      &.disabled {
        color: #db6b5d;
        background: #fdf8f7;
      }
    }
  }
`;

export const ClientRow = ({
  name,
  emailAddress,
  recyclableProduct,
  phoneNo,
  removeClient,
  openEditOverlay,
  date,
}) => {
  const handleOptionSelected = (option) => {
    if (option === "Remove") {
      // Remove Client
      removeClient();
    } else if (option === "Edit") {
      // Open edit overlay
      openEditOverlay();
    }
  };

  return (
    <TableRow>
      <td>
        <div className="wrapper">
          <div className="side">
            <em>{name}</em>
            <em>{phoneNo}</em>
          </div>
        </div>
      </td>
      <td>{recyclableProduct}</td>
      <td>{emailAddress}</td>
      <td>{date}</td>
      <td>
        <button>
          <DropdownMenu
            options={["Edit", "Remove"]}
            onSelected={handleOptionSelected}
          />
        </button>
      </td>
    </TableRow>
  );
};
