import { Toast, ToastStatus } from "components/toast.component";
import { useState } from "react";
import styled from "styled-components";
import ActiveChats from "./active-chats.component";
import ChatScreen from "./chat-screen.component";
import RequestActionComponent from "./request-action.component";
import RequestQueue from "./request-queue.component";

const Container = styled.div`
  height: calc(100vh - 80px);

  display: flex;
  flex-direction: row;

  div.lists {
    height: 100%;
    width: 350px;
    border-right: 1px solid #e8ebed;

    header {
      height: 100px;
      width: 100%;
      display: flex;
      flex-direction: column;

      h5 {
        font-size: 1.6rem;
        display: block;
        color: #1c344f;
        flex: 1;
        font-weight: 500;

        margin: 2rem;
        margin-bottom: 0;
      }

      div.tabs {
        display: flex;
        flex-direction: row;
      }
    }

    div.tab-list {
      height: calc(100% - 100px);
    }
  }

  div.active {
    height: 100%;
    flex: 1;
  }
`;

export const MessageTab = {
  ActiveChats: "Active Chats",
  Queue: "Queue",
};

export const Tab = styled.div`
  flex: 1;
  cursor: pointer;

  div.title {
    margin-left: 2rem;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
    color: ${(props) => (props.active ? "#1C344F" : "#919eab")};
    font-weight: ${(props) => (props.active ? "500" : "400")};
  }

  border-bottom: ${(props) =>
    props.active ? "2px solid #8FC060" : "1px solid #e8ebed"};
`;

const DesktopLiveChat = ({
  rooms,
  requests,
  messages,
  activeRoom,
  setActiveRoom,
  activeRequest,
  setActiveRequest,
  deleteRequest,
  handleNewMessage,
  terminating,
  terminateActiveRoom,
}) => {
  const [activeTab, setActiveTab] = useState(MessageTab.ActiveChats);

  const [error, setError] = useState("");

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={4000}
          width="400px"
          toastStatus={ToastStatus.failed}
        />
      )}

      <div className="lists">
        <header>
          <h5>Messages</h5>

          <div className="tabs">
            <Tab
              active={activeTab === MessageTab.ActiveChats}
              onClick={() => setActiveTab(MessageTab.ActiveChats)}
            >
              <div className="title">Active Chats</div>
            </Tab>

            <Tab
              active={activeTab === MessageTab.Queue}
              onClick={() => setActiveTab(MessageTab.Queue)}
            >
              <div className="title">
                Queue ({requests instanceof Array ? requests.length : 0})
              </div>
            </Tab>
          </div>
        </header>

        <div className="tab-list">
          {activeTab === MessageTab.ActiveChats && (
            <ActiveChats
              rooms={rooms}
              messages={messages}
              activeRoom={activeRoom}
              setActiveRoom={setActiveRoom}
            />
          )}

          {activeTab === MessageTab.Queue && (
            <RequestQueue
              requests={requests}
              activeRequest={activeRequest}
              setActiveRequest={setActiveRequest}
            />
          )}
        </div>
      </div>

      <div className="active">
        {activeTab === MessageTab.ActiveChats && activeRoom && (
          <ChatScreen
            room={activeRoom}
            handleNewMessage={handleNewMessage}
            terminating={terminating}
            terminateActiveRoom={terminateActiveRoom}
          />
        )}

        {activeTab === MessageTab.Queue && activeRequest && (
          <RequestActionComponent
            req={activeRequest}
            deleteRequest={deleteRequest}
            setActiveRequest={setActiveRequest}
            setError={setError}
          />
        )}
      </div>
    </Container>
  );
};

export default DesktopLiveChat;
