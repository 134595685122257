import styled from "styled-components";
import logo from "assets/svg/new-logo.svg";
import { Link } from "react-router-dom";
import { signIn } from "utils/firebase";
import { useState } from "react";
import { Toast, ToastStatus } from "components/toast.component";
import { MiniLoader } from "components/loader.component";
import InputComponent from "components/input.component";
import { ROUTES } from "utils/routes";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import { useEffect } from "react";
import { axiosInstance } from "utils/api";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 64px;
    img {
      width: 154px;
      object-fit: contain;
      display: block;
    }
    h2 {
      font-weight: 500;
      font-size: 16px;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #1c344f;
    text-align: center;
    margin-bottom: 1.2rem;
  }
  em {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: #919eab;
    text-align: center;
    margin-bottom: 3rem;
  }

  form {
    label {
      display: block;
      margin-bottom: 2.5rem;
      &:nth-child(3) {
        margin-bottom: 2.8rem;
      }

      span {
        font-size: 16px;
        color: #1c344f;
        display: block;
        margin-bottom: 12px;
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3.5rem;

      .squaredThree {
        width: 20px;
        position: relative;

        input {
          visibility: hidden;
        }

        input:checked + label:after {
          opacity: 1;
        }

        input:checked + label {
          background: #6955eb;
        }

        label {
          width: 20px;
          height: 20px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          background: none;
          border-radius: 4px;
          border: 1px solid #6955eb;

          &:after {
            content: "";
            width: 9px;
            height: 5px;
            position: absolute;
            top: 4px;
            left: 4px;
            border: 3px solid #ffffff;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }
        }
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #6955eb;
      }
    }

    button {
      display: block;
      margin-left: auto;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #55b653;
      border-radius: 5px;
      border: none;
      padding: 1.3rem;
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    form {
      width: 400px;
    }
  }
`;

export const LoginPage = () => {
  const [email, setEmail] = useState("admin@recyclo.com");
  const [password, setPassword] = useState("adminpass101!");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const { pendingLoginData } = useContext(AppContext);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await signIn(email, password);
      if (response === "success") {
        setSuccess("Login Successful");
        setTimeout(() => setSuccess(""), 4000);
      }
      if (response === "auth-mismatch") {
        setError("Invalid Login Details");
        setTimeout(() => setError(""), 4000);
      }
      if (response === "too-many") {
        setError("Account temporarily suspended. Try again later");
        setTimeout(() => setError(""), 4000);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <Container>
      {success && (
        <Toast
          login
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}
      {error && (
        <Toast
          login
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      {!pendingLoginData && (
        <div className="wrapper">
          <header>
            <img src={logo} alt="Recyclo logo" />
          </header>

          <h1>Welcome to Recyclo!</h1>
          <em>Please sign in to access your account</em>

          <form>
            <label htmlFor="email">
              <span>Email</span>
              <InputComponent
                id="email"
                type="email"
                placeholder="name@domain.comr"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </label>

            <label htmlFor="password">
              <span>Password</span>
              <InputComponent
                id="password"
                type="password"
                placeholder="**********"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </label>

            <div>
              <div class="squaredThree">
                <input
                  type="checkbox"
                  value="None"
                  id="squaredThree"
                  name="check"
                />
                <label for="squaredThree"></label>
              </div>

              <Link to={ROUTES.forgetPassword}> Forgot Password ? </Link>
            </div>

            <button onClick={loading ? null : login}>
              {loading ? <MiniLoader /> : "Sign In"}
            </button>
          </form>
        </div>
      )}

      {pendingLoginData && <Tfa data={pendingLoginData} />}
    </Container>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 64px;
    img {
      width: 154px;
      object-fit: contain;
      display: block;
    }
    h2 {
      font-weight: 500;
      font-size: 16px;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #1c344f;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  em {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: #919eab;
    text-align: center;
    margin-bottom: 3rem;
  }

  form {
    label {
      display: block;
      margin-bottom: 2.5rem;
      &:nth-child(3) {
        margin-bottom: 2.8rem;
      }

      span {
        font-size: 16px;
        color: #1c344f;
        display: block;
        margin-bottom: 12px;
      }
    }

    div.info {
      width: fit-content;
      margin-right: auto;
      margin-left: auto;
      margin-top: 1.6rem;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #748595;

        &.resend {
          color: #55b653;
          cursor: pointer;
        }
      }
    }
  }
`;

const Tfa = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const { setCurrentUser, setPendingLoginData } = useContext(AppContext);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [code, setCode] = useState("");

  const send2faCode = async () => {
    if (sending || loading || !data) return;

    setSending(true);
    try {
      const res = await axiosInstance.get("/auth/tfa?user_id=" + data.id);

      if (res.status === 200) {
        setSuccess(res.data.message);

        setCode("");

        setTimeout(() => {
          setSuccess("");
        }, 4000);
      }
    } catch (error) {
      const msg = error.response?.data?.message || "Something went wrong";

      setError(msg);

      setTimeout(() => {
        setError("");
      }, 4000);
    }
    setSending(false);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (loading || sending || !code || !data) return;

    setLoading(true);
    try {
      const res = await axiosInstance.post("/auth/tfa", {
        code,
        user_id: data.id,
      });

      if (res.status === 200) {
        setSuccess("Request successful");

        setTimeout(() => {
          setSuccess("");
        }, 3500);

        setCurrentUser(data);

        setPendingLoginData(null);

        localStorage.setItem("tfaVerified", "yes");
      } else {
        setError("Verification failed");

        setTimeout(() => {
          setError("");
        }, 4000);
      }
    } catch (error) {
      const msg = error.response?.data?.message || "Something went wrong";

      setError(msg);

      setTimeout(() => {
        setError("");
      }, 4000);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      send2faCode();
    }
  }, [data]);

  return (
    <Wrapper>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}

      <header>
        <img src={logo} alt="Recyclo logo" />
      </header>

      <h1>Two Factor Authentication</h1>
      <em>
        A verification code has been sent to your email. This code will be valid
        for 15 minutes.
      </em>

      <form onSubmit={handleSubmit}>
        <label htmlFor="code">
          <InputComponent
            id="code"
            type="text"
            placeholder="please enter the code here"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            required
          />
        </label>

        <button disabled={loading || sending} type="submit">
          {loading ? <MiniLoader /> : "Verify"}
        </button>

        <div className="info">
          <span>
            Didn’t receive the email?{" "}
            <span className="resend" onClick={send2faCode}>
              Click to resend
            </span>
          </span>
        </div>
      </form>
    </Wrapper>
  );
};
