import React from "react";
import styled from "styled-components";

const SelectInput = styled.select`
  padding: 1.2rem 1.8rem;
  border-radius: 8px;
  background: #f8f9fa;
  border: 1px solid #e8ebed;
  display: block;
  width: 100%;
  border-right: 16px solid transparent;
  height: 56px;
  color: #1c344f;
  font-size: 16px;

  .placeholder {
    color: #aeb8c1;
  }
`;

function SelectComponent({
  id,
  placeholder = "",
  value = "",
  options = [],
  onChange,
  required,
  disabled = false,
}) {
  return (
    <SelectInput
      id={id}
      required={required ? true : false}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {placeholder && (
        <option defaultValue disabled value="">
          {placeholder}
        </option>
      )}

      {options.map((item, idx) => (
        <option key={id + "-" + idx} value={item}>
          {item}
        </option>
      ))}
    </SelectInput>
  );
}

export default SelectComponent;
