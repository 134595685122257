import styled from "styled-components";
import logo from "assets/svg/new-logo.svg";
import { MiniLoader } from "components/loader.component";
import InputComponent from "components/input.component";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/routes";
import backIcon from "assets/svg/arrow-left.svg";
import { axiosInstance } from "utils/api";
import { Toast, ToastStatus } from "components/toast.component";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 64px;
    img {
      width: 154px;
      object-fit: contain;
      display: block;
    }
    h2 {
      font-weight: 500;
      font-size: 16px;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #1c344f;
    text-align: center;
    margin-bottom: 1.2rem;
  }
  em {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: #919eab;
    text-align: center;
    margin-bottom: 3rem;
  }

  form {
    label {
      display: block;
      margin-bottom: 2.5rem;
      &:nth-child(3) {
        margin-bottom: 2.8rem;
      }

      span {
        font-size: 16px;
        color: #1c344f;
        display: block;
        margin-bottom: 12px;
      }
    }

    div.back {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      cursor: pointer;

      img {
        height: 16px;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #6955eb;
        text-align: center;
        text-decoration: none;
        color: #1c344f;
      }
    }

    button {
      display: block;
      margin-left: auto;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #55b653;
      border-radius: 5px;
      border: none;
      padding: 1.3rem;
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    form {
      width: 400px;
    }
  }
`;

const SuccessContainer = styled.div`
  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #1c344f;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  em {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: block;
    color: #919eab;
    text-align: center;
    margin-bottom: 3rem;
  }

  button {
    display: block;
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    background: #55b653;
    border-radius: 5px;
    border: none;
    padding: 1.3rem;
    width: 100%;
  }

  div {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1.6rem;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #748595;

      &.resend {
        color: #55b653;
        cursor: pointer;
      }
    }
  }

  div.back {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    cursor: pointer;

    img {
      height: 16px;
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #6955eb;
      text-align: center;
      text-decoration: none;
      color: #1c344f;
    }
  }
`;

const ForgetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (loading) return;

    setLoading(true);

    try {
      const res = await axiosInstance.get(
        "/auth/admin/reset-password?email=" + email
      );

      if (res.status === 200) {
        setLinkSent(true);
        setSuccess("Link sent successfully");

        setTimeout(() => {
          setSuccess("");
        }, 3500);
      }
    } catch (error) {
      const message = error.response?.data?.message || "Something went wrong";

      setError(message);

      setTimeout(() => {
        setError("");
      }, 3500);
    }

    setLoading(false);
  };

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}

      <div className="wrapper">
        <header>
          <img src={logo} alt="Recyclo logo" />
        </header>

        {!linkSent && (
          <>
            <h1>Forget Password?</h1>
            <em>No worries, we’ll send you reset instructions</em>

            <form onSubmit={handleSubmit}>
              <label htmlFor="email">
                <span>Email</span>
                <InputComponent
                  id="email"
                  type="email"
                  placeholder="name@domain.comr"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </label>

              <button disabled={loading} type="submit">
                {loading ? <MiniLoader /> : "Reset password"}
              </button>

              <div className="back">
                <img src={backIcon} />{" "}
                <Link to={ROUTES.login}>Go back to login</Link>
              </div>
            </form>
          </>
        )}

        {linkSent && (
          <SuccessContainer>
            <h1>Check your email</h1>
            <em>We sent a password reset link to {email}</em>

            <button disabled={loading}>Open mail app</button>

            <div>
              <span>
                Didn’t receive the email?{" "}
                <span className="resend" onClick={handleSubmit}>
                  Click to resend
                </span>
              </span>
            </div>

            <div className="back">
              <img src={backIcon} />{" "}
              <Link to={ROUTES.login}>Go back to login</Link>
            </div>
          </SuccessContainer>
        )}
      </div>
    </Container>
  );
};

export default ForgetPasswordPage;
