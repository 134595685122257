import styled from "styled-components";

const Container = styled.div`
  input {
    position: relative;
    width: 80px;
    height: 40px;
    appearance: none;
    background: #c6c6c6;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    &:checked {
      background: #6955eb;
      &:before {
        left: 45px;
      }
    }
    &:before {
      content: "";
      height: 30px;
      width: 30px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.5s;
    }
  }
`;

export const Toggler = ({ active, setActive }) => {
  return (
    <Container>
      <input
        type="checkbox"
        checked={active}
        onChange={(e) => setActive(e.target.checked)}
      />
    </Container>
  );
};
