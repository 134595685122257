import styled from "styled-components";

import cancelIcon from "assets/svg/cancel.svg";
import { useState } from "react";
import { changePassword, signIn } from "utils/firebase";
import { MiniLoader } from "components/loader.component";
import InputComponent from "components/input.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ebedf0;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      color: #1c344f;
    }

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  form {
    padding: 2rem 2.5rem;

    label {
      display: block;
      margin-bottom: 3rem;

      span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 1.2rem;
      }
    }

    button {
      display: block;
      margin-left: auto;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #55b653;
      border-radius: 8px;
      height: 56px;
      border: none;
      padding: 1rem;
      width: 150px;
    }
  }
`;

const STATES = {
  changePassword: 0,
  login: 1,
};

export const ChangePassword = ({ closeOverlay, setSuccess, setError }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(STATES.changePassword);

  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword)
      return setError("The password must not be empty");

    if (newPassword !== confirmPassword)
      return setError("The passwords don't match");
    setLoading(true);
    try {
      let isPasswordChanged = await changePassword(newPassword);
      if (isPasswordChanged === "success") {
        setSuccess("Password updated successfully");
        closeOverlay();
      } else if (isPasswordChanged === "reauthenticate") {
        setView(STATES.login);
        setError("You must re-login first");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let result = await signIn(email, password);
      if (result === "success") setView(STATES.changePassword);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      {view === STATES.changePassword && (
        <Container>
          <header>
            <h1>Change Password</h1>
            <button onClick={closeOverlay}>
              <img src={cancelIcon} alt="" />
            </button>
          </header>

          <form id="changePasswordForm" onSubmit={submitNewPassword}>
            <label htmlFor="newPassword">
              <span>New Password</span>
              <InputComponent
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </label>

            <label htmlFor="confirmPassword">
              <span>Confirm New Password</span>
              <InputComponent
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </label>

            <button type="submit" form="changePasswordForm" disabled={loading}>
              {loading ? <MiniLoader /> : "Change Password"}
            </button>
          </form>
        </Container>
      )}
      {view === STATES.login && (
        <Container>
          <header>
            <h1>Login to Change Password</h1>
            <button onClick={closeOverlay}>
              <img src={cancelIcon} alt="" />
            </button>
          </header>
          <form id="loginForm" onSubmit={login}>
            <label htmlFor="email">
              <span>Email</span>
              <InputComponent
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
            <label htmlFor="password">
              <span>Password</span>
              <InputComponent
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </label>

            <button type="submit" form="loginForm" disabled={loading}>
              {loading ? <MiniLoader /> : "Login"}
            </button>
          </form>
        </Container>
      )}
    </>
  );
};
