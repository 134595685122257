import styled from "styled-components";
import search from "assets/svg/search.svg";
import FilterButton from "components/filter-button.component";
import plusIcon from "assets/svg/plus.svg";
import { AdminCard } from "components/admin-card.component";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import { useEffect, useState } from "react";
import { Toast, ToastStatus } from "components/toast.component";
import ActiveFilter from "components/active-filter.component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  header {
    border-bottom: 1px solid #dce2e6;
    padding-bottom: 2rem;
    & > h1 {
      font-size: 18px;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: auto;
    }

    div.actions {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      marging-bottom: 1rem;

      div.input-wrapper {
        width: 360px;
        margin-right: 1.6rem;
        background: #e8ebed;
        box-sizing: border-box;
        height: 56px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.8rem;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 10px;
        }

        input {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          background: transparent;
          border: none;
          width: 60%;
          &:focus {
            outline: none;
          }
        }
      }
    }

    button.add {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      background: #55b653;
      border-radius: 12px;
      border: none;
      width: unset;
      height: 56px;
      padding: 1.8rem;

      img {
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }
  }

  div.main {
    flex: 1;
  }

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;

    margin-right: 1rem;
    margin-left: 1rem;

    position: sticky;
    bottom: 0;

    background-color: white;

    @media screen and (min-width: 900px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

const AdminMobile = ({ admins, editAdmin, deleteAdmin, openOverlay }) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [filtered, setFiltered] = useState(admins);

  useEffect(() => {
    setFiltered(admins);
  }, [admins]);

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredAdmin = () => {
    const result = admins.filter((admin) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(admin.status.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredAdmin();
    } else {
      setFiltered(admins);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredAdmin();
      } else {
        setFiltered(admins);
      }
    } else {
      const result = admins.filter((admin) => {
        const fullname = admin.firstname + " " + admin.lastname;

        const phoneNo = getUserPhoneNumber(admin);

        if (
          admin.email.toLowerCase().includes(query) ||
          admin.firstname?.toLowerCase()?.includes(query) ||
          admin.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(admin.status.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="80%"
        />
      )}
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="80%"
        />
      )}

      <header>
        <h1>Admin List</h1>

        <div className="actions">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>

          <FilterButton
            page={"admin"}
            options={["Online", "Offline"]}
            onSelect={onFilterSelected}
          />

          <button
            className="add"
            onClick={(e) => {
              e.preventDefault();
              openOverlay();
            }}
          >
            <img src={plusIcon} alt="" />
          </button>
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="admin"
        />
      )}

      <div className="main">
        {currentItems.map((admin) => (
          <AdminCard
            key={admin.id}
            id={admin.id}
            name={`${admin.firstname} ${admin.lastname}`}
            status={admin.status === "online"}
            email={admin.email}
            number={getUserPhoneNumber(admin)}
            enabledStatus={admin.enabled}
            editAdmin={editAdmin}
            setSuccess={setSuccess}
            setError={setError}
            deleteAdmin={deleteAdmin}
          />
        ))}
      </div>

      {currentItems && currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={filtered.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};

export default AdminMobile;
