import React from "react";
import styled from "styled-components";

const TextInput = styled.div`
  border-radius: 8px;
  background: #f8f9fa;
  border: 1px solid #e8ebed;
  padding: 1.2rem 1.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;

  div.prefix {
    margin-right: 1rem;
  }

  input {
    display: block;
    width: 100%;
    background-color: #f8f9fa;
    outline: none;
    border: none;
    color: #1c344f;
    font-size: 16px;
    flex: 1;

    &::placeholder {
      color: #aeb8c1;
    }
  }
`;

const InputComponent = ({
  id,
  type = "text",
  placeholder = "",
  value = "",
  onChange,
  required,
  suffixIcon,
  prefixIcon,
  disabled,
}) => {
  return (
    <TextInput>
      {prefixIcon && <div className="prefix">{prefixIcon}</div>}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        min={0}
        readOnly={disabled ? true : false}
        required={required ? true : false}
      />

      {suffixIcon && suffixIcon}
    </TextInput>
  );
};

export default InputComponent;
