import styled from "styled-components";
import cancelIcon from "assets/svg/cancel.svg";
import { useState } from "react";
import { axiosInstance } from "utils/api";
import { MiniLoader } from "components/loader.component";
import { getToken } from "utils/firebase";
import { Toast, ToastStatus } from "components/toast.component";
import InputComponent from "components/input.component";
import LinearIndicator from "components/linear-indicator.component";
import { useEffect } from "react";
import SelectComponent from "components/select-component";
import PhoneInput from "components/phone-input.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .indicator {
    margin-bottom: 3rem;
  }

  header {
    padding: 2rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: 500;
      font-size: 20px;
      color: #1c344f;
    }

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  form {
    flex: 1;
    overflow-y: auto;
    padding: 2rem 2.5rem;
    .name {
      display: grid;
      gap: 20px;
    }
    label {
      display: block;
      margin-bottom: 3rem;
      span {
        font-size: 16px;
        color: #1c344f;
        display: block;
        margin-bottom: 12px;
      }
    }
    div.button-container {
      margin-left: auto;
      margin-bottom: 2rem;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        display: block;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        background: #55b653;
        border-radius: 8px;
        border: none;
        padding: 1.5rem;
        height: 56px;
        min-width: 159px;
        margin-right: 1.6rem;
        /* width: 105px; */

        &.back {
          color: #aeb8c1;
          border: 1px solid #cbd1d7;
          background-color: #f8f9fa;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    form {
      .name {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

export const AccessLevel = {
  Super: "super",
  Normal: "normal",
};

export const NewAdmin = ({
  closeOverlay,
  addAdmin,
  setHasAddedAdmin,
  setNewAdminPassword,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [dialCode, setDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [country, setCountry] = useState("");
  const [accessLevel, setAccessLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [step, setStep] = useState(1);

  const nextStep = () => setStep((prev) => prev + 1);

  const prevStep = () => {
    if (step > 1) setStep((prev) => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step < 2) {
      nextStep();
      return;
    }

    setError("");
    setLoading(true);
    const admin = {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      phonenumber: phoneNumber,
      country,
      access_level: accessLevel,
      dialCode,
    };
    let accessToken = await getToken();
    if (!accessToken) return;
    try {
      let response = await axiosInstance.post("/admin", admin, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setLoading(false);
      console.log(response);
      if (response.status === 201) {
        setNewAdminPassword(admin.password);
        setHasAddedAdmin(true);
        addAdmin(response.data.data);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(e);
    }
  };

  const reset = () => {
    setStep(1);
  };

  const [fetchingCountries, setFetchingCountries] = useState([]);
  const [countries, setCountries] = useState([]);

  const getCountryApiToken = async () => {
    try {
      const res = await axiosInstance.get(
        "https://www.universal-tutorial.com/api/getaccesstoken",
        {
          headers: {
            Accept: "application/json",
            "api-token":
              "zNf5fTijqykek897QLfO1w3xOqSVgR0sEt94A4Djl8hEeb3Wurpwn6Fo-nHiq_SICFc",
            "user-email": "pelumip15@gmail.com",
          },
        }
      );

      if (res.status === 200) {
        return res.data.auth_token;
      }
    } catch (error) {
      return null;
    }
  };

  const getAllCountries = async () => {
    setFetchingCountries(true);

    try {
      const token = await getCountryApiToken();

      if (!token) {
        return;
      }

      const res = await axiosInstance.get(
        "https://www.universal-tutorial.com/api/countries",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (res.status === 200) {
        setCountries(res.data);
      }
    } catch (e) {}

    setFetchingCountries(false);
  };

  useEffect(() => {
    getAllCountries();
    return reset;
  }, []);

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}
      <header>
        <h1>Create New Admin</h1>
        <button onClick={closeOverlay}>
          <img src={cancelIcon} alt="" />
        </button>
      </header>

      <div className="indicator">
        <LinearIndicator value={step / 4} />
      </div>

      <form id="newAdminForm" onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <div className="name">
              <label htmlFor="firstName">
                <span>First Name</span>
                <InputComponent
                  id="firstName"
                  placeholder="Enter your first name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  required
                />
              </label>
              <label htmlFor="lastName">
                <span>Last Name</span>
                <InputComponent
                  id="lastName"
                  placeholder="Enter your last name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                />
              </label>
            </div>

            <label htmlFor="phone">
              <span>Phone Number</span>
              <PhoneInput
                id="phone"
                placeholder="Enter your phone number"
                onChange={(phone, country) => {
                  setDialCode(country.dialCode);
                  setPhoneNumber(phone.slice(country.dialCode.length));
                }}
                value={dialCode + phoneNumber}
                required
              />
            </label>

            <label htmlFor="email">
              <span>Email</span>
              <InputComponent
                id="email"
                type="email"
                placeholder="name@domain.comr"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </label>
          </>
        )}

        {step === 2 && (
          <>
            <label htmlFor="access-level">
              <span>Access Level</span>
              <SelectComponent
                id="access-level"
                placeholder="Select access level"
                value={accessLevel}
                onChange={(e) => setAccessLevel(e.target.value)}
                options={[AccessLevel.Super, AccessLevel.Normal]}
                required
              />
            </label>

            <label htmlFor="country">
              <span>Country</span>
              <SelectComponent
                id="country-select"
                placeholder="Select country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                options={countries.map((e) => e.country_name)}
                disabled={fetchingCountries}
                required
              />
            </label>

            <label htmlFor="password">
              <span>Password</span>
              <InputComponent
                id="password"
                type="password"
                placeholder="**********"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </label>
          </>
        )}

        <div className="button-container">
          {step > 1 && (
            <button className="back" disabled={loading} onClick={prevStep}>
              Back
            </button>
          )}

          <button type="submit" form="newAdminForm" disabled={loading}>
            {loading ? <MiniLoader /> : step === 1 ? "Continue" : "Create"}
          </button>
        </div>
      </form>
    </Container>
  );
};
