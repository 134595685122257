import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .containerClass {
    border-radius: 8px;
    border: 1px solid #e8ebed;

    .inputClass {
      background: #f8f9fa;
      outline: none;
      border-radius: 8px;
      border: none;
      height: 56px;
      width: 100%;
      color: #1c344f;
      font-size: 16px;
    }

    .buttonClass {
      border-radius: 8px;
      background: #f8f9fa;
      border: none;

      .selected-flag {
        border-radius: 8px;
        &:hover {
          background-color: #f8f9fa;
        }
        &:focus {
          background-color: #f8f9fa;
        }
      }
    }
  }
`;

function PhoneInputComponent({ value, onChange, placeholder, required, id }) {
  return (
    <Container>
      <PhoneInput
        country={"gb"}
        value={value}
        onChange={onChange}
        disableDropdown
        inputProps={{
          id: { id },
          name: { id },
          required: required ? true : false,
        }}
        placeholder={placeholder}
        inputClass="inputClass"
        containerClass="containerClass"
        buttonClass={"buttonClass"}
        dropdownClass={"dropdownClass"}
      />
    </Container>
  );
}

export default PhoneInputComponent;
