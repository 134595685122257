import styled from "styled-components";
import { UserRow } from "./user.row.component";
import requestImage1 from "assets/png/requestImage1.png";
import search from "assets/svg/search.svg";
import { useEffect, useState } from "react";
import { Toast, ToastStatus } from "components/toast.component";
import { getUserPhoneNumber, usePagination } from "utils/shared";
import PaginationComponent from "components/pagination/pagination-component";
import PaginationInfoComponent from "components/pagination/pagination-info.component";
import PaginationPageControllerComponent from "components/pagination/pagination-page-controller.component";
import FilterButton from "components/filter-button.component";
import ActiveFilter from "components/active-filter.component";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-disrection: column;
    align-items: center;
    justify-content: space-between;
  }

  div.table-wrapper {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid #e8ebed;
        width: 100%;
      }

      td,
      th {
        &:last-child {
          padding-right: 2rem;
        }
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #1c344f;
        button {
          background: transparent;
          border: none;
          img {
            width: 20px;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;

        tr {
          background-color: #f9fafb;
          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;

        color: #061927;
      }
      div.right {
        display: flex;
        align-items: center;
        div.input-wrapper {
          width: 360px;
          margin-right: 1.6rem;
          background: #e8ebed;
          border-radius: 12px;
          display: flex;
          height: 56px;
          flex-direction: row;
          align-items: center;
          padding: 1.8rem;
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
          }
          input {
            display: inline-block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            background: transparent;
            border: none;
            width: 330px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
`;

export const UserTable = ({
  users,
  editUser,
  deleteUser,
  showPointsInfo,
  setPointsInfo,
}) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [filtered, setFiltered] = useState(users);

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  const customSetTimeout = (timeoutCallback) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let id = setTimeout(timeoutCallback, 4000);
    setTimeoutId(id);
  };

  const {
    itemOffset,
    currentItems,
    endOffset,
    pageCount,
    handlePageClick,
    itemsPerPage,
    setItemsPerPage,
  } = usePagination(filtered);

  const [activeFilter, setActiveFilter] = useState([]);

  const clearFilter = () => setActiveFilter([]);

  const removeFilter = (value) => {
    setActiveFilter((prev) => prev.filter((item) => item !== value));
  };

  const showFilteredUser = () => {
    const result = users.filter((user) =>
      activeFilter
        .map((item) => item.toLowerCase())
        .includes(user.status.toLowerCase())
    );

    setFiltered(result);
  };

  useEffect(() => {
    if (activeFilter.length > 0) {
      showFilteredUser();
    } else {
      setFiltered(users);
    }
  }, [activeFilter]);

  const onFilterSelected = (value) => {
    if (!activeFilter.includes(value)) {
      setActiveFilter((prev) => [value, ...prev]);
    }
  };

  const onSearch = (e) => {
    const query = e.target.value.toLowerCase().trim();

    if (!query) {
      if (activeFilter.length > 0) {
        showFilteredUser();
      } else {
        setFiltered(users);
      }
    } else {
      const result = users.filter((user) => {
        const fullname = user.firstname + " " + user.lastname;

        const address = user.default_address
          ? `${user.default_address.address_line1}, ${user.default_address.city}, ${user.default_address.state}`
          : "";

        const phoneNo = getUserPhoneNumber(user);

        if (
          user.email.toLowerCase().includes(query) ||
          user.firstname?.toLowerCase()?.includes(query) ||
          user.lastname?.toLowerCase()?.includes(query) ||
          fullname.toLowerCase().includes(query) ||
          address.toLowerCase().includes(query) ||
          phoneNo.includes(query)
        ) {
          if (
            activeFilter.length > 0 &&
            !activeFilter
              .map((item) => item.toLowerCase())
              .includes(user.status.toLowerCase())
          ) {
            return false;
          }

          return true;
        }

        return false;
      });

      setFiltered(result);
    }
  };

  return (
    <Container>
      {success && (
        <Toast
          text={success}
          timeout={3000}
          toastStatus={ToastStatus.success}
          width="400px"
        />
      )}
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}
      <header>
        <h1>User List</h1>
        <div className="right">
          <div className="input-wrapper">
            <img src={search} alt="" />
            <input type="text" placeholder="Search name" onChange={onSearch} />
          </div>

          <FilterButton
            page={"user"}
            options={["Enabled", "Disabled"]}
            onSelect={onFilterSelected}
          />
        </div>
      </header>

      {activeFilter.length > 0 && (
        <ActiveFilter
          clearFilter={clearFilter}
          removeFilter={removeFilter}
          activeFilters={activeFilter}
          page="user"
        />
      )}

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>email address</th>
              <th>address</th>
              <th>points earned</th>
              <th>status</th>
              <th>actions</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((user) => (
              <UserRow
                key={user.id}
                id={user.id}
                user={user}
                deleteUser={deleteUser}
                name={`${user.firstname || ""} ${user.lastname || ""}`}
                status={user.status === "enabled"}
                emailAddress={user.email}
                phoneNo={getUserPhoneNumber(user)}
                pointsPerMin={user.points + user.referral_points}
                userImage={user.avatar || requestImage1}
                address={
                  user.default_address
                    ? `${user.default_address.address_line1}, ${user.default_address.city}, ${user.default_address.state}`
                    : ""
                }
                setError={setError}
                setSuccess={setSuccess}
                customSetTimeout={customSetTimeout}
                editUser={editUser}
                showPointsInfo={showPointsInfo}
                setPointsInfo={setPointsInfo}
              />
            ))}
          </tbody>
        </table>
      </div>

      {currentItems.length > 0 && (
        <div className="pagination-wrapper">
          <PaginationInfoComponent
            itemOffset={itemOffset}
            endOffset={endOffset}
            size={users.length}
          />

          <PaginationComponent
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />

          <PaginationPageControllerComponent
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      )}
    </Container>
  );
};
