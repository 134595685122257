import styled from "styled-components";
import { UserTable } from "components/tables/user/user-table.component";
import { useContext, useEffect, useState } from "react";
import { Overlay } from "components/overlay.component";
import { PointsInfo } from "components/overlays/points-info.overlay";
import { AppContext } from "context/app.provider";
import { Toast, ToastStatus } from "components/toast.component";
import UserDeleteConfirmation from "components/overlays/user-delete.overlay";
import UserMobile from "components/tables/user/user-mobile.component";

const Container = styled.div`
  height: calc(100vh - 80px);

  div.table-container {
    display: none;
  }

  div.container {
    margin-right: 2rem;
    margin-left: 2rem;
    height: 100%;
  }

  div.container > header {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dce2e6;

    h1 {
      font-size: 18px;
      font-weight: normal;
      color: #1c344f;
      display: block;
      margin-bottom: auto;
    }
  }

  @media screen and (min-width: 1000px) {
    div.container {
      display: none;

      header {
        padding: 4rem 2.5rem 3.2rem;
      }
    }

    div.table-container {
      display: block;
      margin-right: 2rem;
      margin-left: 2rem;
      height: 100%;
    }
  }
`;

export const UserPage = () => {
  const { users, setUsers } = useContext(AppContext);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [pointsInfo, setPointsInfo] = useState(null);

  const editUser = (id, newStatus) => {
    setUsers((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, status: newStatus };
        }

        return item;
      })
    );
  };

  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);

  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("admin");

  const showDeleteOverlay = (id, type) => {
    setUserId(id);
    setUserType(type);
    setIsDeleteOverlayOpen(true);
  };

  const reset = () => {
    setError("");
    setUserId(null);
    setUserType("admin");
    setIsDeleteOverlayOpen(false);
  };

  useEffect(() => {
    return reset;
  }, []);

  return (
    <Container>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <Overlay
        closeOverlay={() => setIsOverlayOpen(false)}
        isVisible={isOverlayOpen}
        Component={PointsInfo}
        extraProps={{
          pointsInfo,
        }}
      />

      <Overlay
        closeOverlay={() => setIsDeleteOverlayOpen(false)}
        isVisible={isDeleteOverlayOpen}
        Component={UserDeleteConfirmation}
        extraProps={{ setError, userId, type: userType }}
      />

      <div className="container">
        <UserMobile
          users={users}
          deleteUser={showDeleteOverlay}
          showPointsInfo={() => setIsOverlayOpen(true)}
          setPointsInfo={setPointsInfo}
          editUser={editUser}
        />
      </div>

      <div className="table-container">
        <UserTable
          showPointsInfo={() => setIsOverlayOpen(true)}
          setPointsInfo={setPointsInfo}
          editUser={editUser}
          // removeUser={removeUser}
          users={users}
          deleteUser={showDeleteOverlay}
        />
      </div>
    </Container>
  );
};
