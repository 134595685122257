import DesktopLiveChat from "components/live-chat/desktop.component";
import MobileLiveChat from "components/live-chat/mobile.component";
import Loader from "components/loader.component";
import { AppContext } from "context/app.provider";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { axiosInstance } from "utils/api";
import { getToken } from "utils/firebase";
import socket from "utils/socket";

const LiveChatPage = () => {
  // const [requests, setRequests] = useState([]);

  const [initializing, setInitializing] = useState(false);
  const [messages, setMessages] = useState([]);

  const { chatRequests, setChatRequests } = useContext(AppContext);

  const [rooms, setRooms] = useState([]);
  const [activeRoom, setActiveRoom] = useState(null);
  const [activeRequest, setActiveRequest] = useState(null);

  const [terminating, setTerminating] = useState(false);

  useEffect(() => console.log(messages), [messages]);

  const handleChatRequest = async (data) => {
    console.log("New chat request", data);

    if (data instanceof Array) {
      const requests = data.map((item) => {
        const request_date = new Date(
          item.request_date._seconds * 1000
        ).toDateString();

        item.request_date = request_date;

        return item;
      });

      setChatRequests(requests);
      return;
    }

    const request_date = new Date(
      data.request_date._seconds * 1000
    ).toDateString();

    const request = {
      id: data.id,
      user: {
        id: data.user.id,
        email: data.user.email,
        firstname: data.user.firstname,
        lastname: data.user.lastname,
      },
      request_date: request_date,
    };

    if (chatRequests.filter((item) => request.id === item.id).length === 0) {
      setChatRequests((prev) => [request, ...prev]);
    }
  };

  const handleChatInitiated = (data) => {
    setRooms((prev) => [data, ...prev]);
  };

  const handleError = (data) => console.log("Error", data);

  const deleteRequest = (data) => {
    setChatRequests((prev) => {
      const result = prev.filter((req) => req.id !== data.id);

      return result;
    });
  };

  const terminateActiveRoom = async () => {
    if (!activeRoom) return;

    setTerminating(true);

    if (socket.connected) {
      socket.emit("chat_terminated", { room: activeRoom.room }, ({ error }) => {
        setTerminating(false);

        if (error) {
          console.log(error);
        } else {
          console.log("Session successfully terminated");
          setRooms((prev) =>
            prev.filter((room) => room.room !== activeRoom.room)
          );
          setActiveRoom(null);
          setMessages([]);
        }
      });
    } else {
      console.log({ error: "No socket connection", type: "error" });
    }
  };

  const handleNewMessage = (message) => {
    console.log("New message", message);

    setRooms((prev) => {
      const result = prev.map((room) => {
        if (room.room === message.room) {
          const exists = room.messages.filter((item) => item.id === message.id);

          console.log("Exists", exists);

          if (exists.length === 0) room.messages = [...room.messages, message];

          console.log("Updated messages", room.messages);

          return room;
        }

        return room;
      });

      return result;
    });

    const chatBox = document.querySelector("div.chat");

    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  const setupListeners = async () => {
    if (!socket.connected) {
      let accessToken = await getToken();
      if (accessToken) {
        socket.auth = { token: accessToken };
        socket.connect();
      }
    }

    if (!socket.hasListeners("chat_request")) {
      socket.on("chat_request", handleChatRequest);
    }

    if (!socket.hasListeners("chat_initiated")) {
      socket.on("chat_initiated", handleChatInitiated);
    }

    if (!socket.hasListeners("error")) {
      socket.on("error", handleError);
    }

    if (!socket.hasListeners("message")) {
      socket.on("message", handleNewMessage);
    }
  };

  const initializeConnection = async () => {
    setInitializing(true);

    if (socket.connected) {
      socket.emit("join_existing", ({ error, rooms }) => {
        setInitializing(false);

        if (error) {
          console.log("Error joining existing", error);
          setRooms([]);
        } else {
          console.log("Rooms", rooms);
          setRooms(rooms);
        }
      });
    }
  };

  // const getInitialRequests = async () => {
  //   try {
  //     let accessToken = await getToken();
  //     if (!accessToken) return;
  //     let response = await axiosInstance.get("/chat-request", {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     setRequests(response.data.data);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  useEffect(() => {
    setupListeners();
    initializeConnection();
    // getInitialRequests();

    return () => {
      socket.removeAllListeners("chat_request");
      socket.removeAllListeners("chat_initiated");
      socket.removeAllListeners("error");
      // socket.removeAllListeners("chat_request_delete");
      socket.removeAllListeners("message");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mediaQuery = window.matchMedia("(min-width: 900px)");
  const [showDesktopVersion, setShowDesktopVersion] = useState(
    mediaQuery.matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowDesktopVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  if (initializing) return <Loader />;

  return showDesktopVersion ? (
    <DesktopLiveChat
      rooms={rooms}
      requests={chatRequests}
      messages={messages}
      activeRoom={activeRoom}
      setActiveRoom={setActiveRoom}
      activeRequest={activeRequest}
      setActiveRequest={setActiveRequest}
      deleteRequest={deleteRequest}
      handleNewMessage={handleNewMessage}
      terminating={terminating}
      terminateActiveRoom={terminateActiveRoom}
    />
  ) : (
    <MobileLiveChat
      rooms={rooms}
      requests={chatRequests}
      messages={messages}
      activeRoom={activeRoom}
      setActiveRoom={setActiveRoom}
      activeRequest={activeRequest}
      setActiveRequest={setActiveRequest}
      deleteRequest={deleteRequest}
      handleNewMessage={handleNewMessage}
      terminating={terminating}
      terminateActiveRoom={terminateActiveRoom}
    />
  );
};

export { LiveChatPage };
