import styled from "styled-components";

const Container = styled.div`
  width: 70%;
  max-width: 250px;

  border-radius: 0px 9.90476px 9.90476px 9.90476px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  ${({ left }) =>
    left &&
    `
    background: #f7f6fe;
    margin-left: 2.5rem;
  p {
    font-size: 14px;
    line-height: 20px;
    color: #1c344f;
    text-align: left;
  }
  em {
    text-align: right;
    font-style: normal;
    margin-left: auto;
    display: block;
    font-size: 12px;
    color: #748595;
  }
  `}

  ${({ right }) =>
    right &&
    `
    background: #6955EB;
    margin-right: 2.5rem;
    margin-left: auto;
  p {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: left;
  }
  em {
      text-align: right;
    font-style: normal;
    margin-left: auto;
    display: block;
    color: #EFF1F5;
    font-size: 12px;
  }
  `}

@media screen and (min-width: 500px) {
    max-width: 400px;
  }

  @media screen and (min-width: 700px) {
    max-width: 500px;
  }

  @media screen and (min-width: 1000px) {
    max-width: 550px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 700px;
  }
`;

export const Message = ({ left, right, message }) => {
  return (
    <Container left={left} right={right}>
      <p>{message.message}</p>
      <em>08:05</em>
    </Container>
  );
};
