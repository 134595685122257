import { RequestAction } from "components/tables/request/request.row.component";
import { useEffect, useState } from "react";

const showLoadStatus = () => {
  const load_status = document.getElementById("load-status");

  if (load_status) {
    if (!load_status.classList.contains("show-load-status")) {
      load_status.classList.add("show-load-status");
    }

    if (load_status.classList.contains("hide-load-status")) {
      load_status.classList.remove("hide-load-status");
    }
  }
};

const hideLoadStatus = () => {
  const load_status = document.getElementById("load-status");

  if (load_status) {
    if (!load_status.classList.contains("hide-load-status")) {
      load_status.classList.add("hide-load-status");
    }

    if (load_status.classList.contains("show-load-status")) {
      load_status.classList.remove("show-load-status");
    }
  }
};

const showInitLoader = () => {
  const spinner = document.getElementById("fullscreen-custom-spinner");

  if (spinner) {
    if (!spinner.classList.contains("show-fullscreen-loader")) {
      spinner.classList.add("show-fullscreen-loader");
    }

    if (spinner.classList.contains("hide-fullscreen-loader")) {
      spinner.classList.remove("hide-fullscreen-loader");
    }
  }
};

const hideInitLoader = () => {
  const spinner = document.getElementById("fullscreen-custom-spinner");

  if (spinner) {
    if (!spinner.classList.contains("hide-fullscreen-loader")) {
      spinner.classList.add("hide-fullscreen-loader");
    }

    if (spinner.classList.contains("show-fullscreen-loader")) {
      spinner.classList.remove("show-fullscreen-loader");
    }
  }
};

const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const RequestProductClass = {
  General: "General",
  Specific: "Specific",
};

const getRequestStatusFromAction = (action) => {
  switch (action) {
    case RequestAction.Accept:
      return "Ongoing";

    case RequestAction.Decline:
      return "Declined";

    case RequestAction.Complete:
      return "Completed";

    case RequestAction.Cancel:
      return "Cancelled";

    default:
      return null;
  }
};

const getUserPhoneNumber = (admin) => {
  const dialCode = admin.dialCode;
  const phone = admin.phonenumber;

  if (dialCode && phone) {
    return "+" + dialCode + phone;
  }

  return "";
};

const usePagination = (list) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [endOffset, setEndOffset] = useState(0);

  const reset = () => {
    setItemOffset(0);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    const result = list.slice(itemOffset, endOffset);

    let newEndOffset = 0;

    if (endOffset > list.length - 1) {
      newEndOffset = list.length;
    } else {
      newEndOffset = endOffset;
    }

    setEndOffset(newEndOffset);

    setCurrentItems(result);
    setPageCount(Math.ceil(list.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, list]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  return {
    itemOffset,
    endOffset,
    currentItems,
    pageCount,
    setItemsPerPage,
    itemsPerPage,
    reset,
    handlePageClick,
  };
};

export {
  showLoadStatus,
  hideLoadStatus,
  usePagination,
  showInitLoader,
  hideInitLoader,
  RequestProductClass,
  useOutsideAlerter,
  getRequestStatusFromAction,
  getUserPhoneNumber,
};
