import { AppContext } from "context/app.provider";
import { ClientPage } from "pages/client.page";
import ForgetPasswordPage from "pages/forget-password.page";
import { LiveChatPage } from "pages/live-chat.page";
import { LoginPage } from "pages/login.page";
import { ProductsPage } from "pages/product.page";
import { RecyclePage } from "pages/recycle.page";
import { SettingsPage } from "pages/settings.page";
import { UserPage } from "pages/user.page";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { axiosInstance } from "utils/api";
import { auth } from "utils/firebase";
import { ROUTES } from "utils/routes";
import { hideInitLoader } from "utils/shared";
import { Layout } from "./components/layout.component";
import { AdminPage } from "./pages/admin.page";
import { HomePage } from "./pages/home.page";

const App = () => {
  const { setCurrentUser, reset, setPendingLoginData } = useContext(AppContext);
  // const [loading, setLoading] = useState(true);

  const getUserProfile = async (accessToken, uid) => {
    console.log("get prof", uid, accessToken);
    try {
      const res = await axiosInstance.get("/user", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      hideInitLoader();

      if (res.status === 200) {
        const login = {
          ...res.data.data,
          id: uid,
          imageUrl: res.data.data.avatar,
          accessToken,
        };

        if (!res.data.data.tfa) {
          setCurrentUser(login);
        } else {
          const tfaVerified = localStorage.getItem("tfaVerified");

          if (tfaVerified) {
            setCurrentUser(login);
          } else {
            setPendingLoginData(login);
          }
        }
      }
    } catch (error) {
      hideInitLoader();
    }
  };

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const { accessToken, uid } = authUser;

        getUserProfile(accessToken, uid);
      } else {
        hideInitLoader();
        setCurrentUser();
        reset();
      }
    });
    return unsubscribeFromAuth;
    //eslint-disable-next-line
  }, [auth]);

  return (
    <Routes>
      <Route path={ROUTES.home} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={ROUTES.login} element={<LoginPage />} />
        <Route path={ROUTES.admin} element={<AdminPage />} />
        <Route path={ROUTES.user} element={<UserPage />} />
        <Route path={ROUTES.client} element={<ClientPage />} />
        <Route path={ROUTES.recycle} element={<RecyclePage />} />
        <Route path={ROUTES.products} element={<ProductsPage />} />
        <Route path={ROUTES.liveChat} element={<LiveChatPage />} />
        <Route path={ROUTES.settings} element={<SettingsPage />} />
      </Route>
      <Route path={ROUTES.forgetPassword} element={<ForgetPasswordPage />} />
    </Routes>
  );
};

export default App;
