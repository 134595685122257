import styled from "styled-components";
import crownIcon from "assets/svg/crown.svg";
import { ProductClass } from "components/product-card.component";
import DropdownMenu from "components/dropdown-menu.component";

const TableRow = styled.tr`
  div.wrapper {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      margin-right: 1rem;
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
    div.side {
      display: flex;
      flex-direction: column;
      em {
        font-style: normal;
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #1c344f;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:last-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
        }
      }
    }
  }
  td.points {
    em {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1c344f;
      display: inline-block;
      margin-right: 8px;
      width: 70px;
      padding: 1rem;
      border-radius: 60px;
      background: #fef4e6;
      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
  }
  td.status {
    em {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      border-radius: 60px;
      padding: 4px 12px;
      &.online {
        color: #6955eb;
        background: #f7f6fe;
      }
      &.offline {
        color: #db6b5d;
        background: #fdf8f7;
      }
    }
  }
  td.pClass {
    em.pClass {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      border-radius: 60px;
      padding: 4px 12px;
      &.general {
        color: #71bf5f;
        background: #ebf6e9;
      }
      &.specific {
        color: #6955eb;
        background: #f7f6fe;
      }
    }
  }
  td.qty {
    text-transform: uppercase;
  }
`;

const options = ["Edit", "Remove"];

export const ProductRow = ({
  productId,
  productName,
  productClass,
  minQuantity,
  pointsPerMin,
  openEditOverlay,
  openDeleteOverlay,
}) => {
  const handleOptionSelected = (option) => {
    if (option === "Edit") {
      openEditOverlay();
    } else {
      openDeleteOverlay(productId);
    }
  };

  return (
    <TableRow>
      <td>{productId}</td>
      <td>{productName}</td>

      <td className="pClass">
        <em
          className={`pClass ${
            productClass === ProductClass.general && "general"
          } ${productClass === ProductClass.specific && "specific"}`}
        >
          {productClass === ProductClass.general && "General"}
          {productClass === ProductClass.specific && "Specific"}
        </em>
      </td>
      <td className="qty">{minQuantity}</td>
      <td className="points">
        <em>
          <img src={crownIcon} alt="" />
          <span>{pointsPerMin}</span>
        </em>
      </td>
      <td>
        <DropdownMenu options={options} onSelected={handleOptionSelected} />
      </td>
    </TableRow>
  );
};
