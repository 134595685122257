import styled from "styled-components";
import search from "assets/svg/search.svg";
import RequestItem from "./request-item.component";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  div.input-wrapper {
    width: auto;
    margin-right: 1.6rem;
    background: #e8ebed;
    border-radius: 12px;
    display: flex;
    height: 42px;
    margin: 2rem;
    flex-direction: row;
    align-items: center;
    padding: 1.8rem;

    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
    }
    input {
      display: inline-block;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      background: transparent;
      border: none;
      width: 330px;
      &:focus {
        outline: none;
      }
    }
  }

  div.list {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
`;

const RequestQueue = ({ requests, activeRequest, setActiveRequest }) => {
  const onSearch = (e) => {};

  return (
    <Container>
      <div className="input-wrapper">
        <img src={search} alt="" />
        <input type="text" placeholder="Search name" onChange={onSearch} />
      </div>

      <div className="list">
        {requests instanceof Array &&
          requests.map((req) => {
            return (
              <RequestItem
                key={req.id}
                req={req}
                activeRequest={activeRequest}
                setActiveRequest={setActiveRequest}
              />
            );
          })}
      </div>
    </Container>
  );
};
export default RequestQueue;
