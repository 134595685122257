import styled from "styled-components";
import { Tabs } from "pages/recycle.page";

import userIcon from "assets/svg/user-2.svg";
import DropdownMenu from "components/dropdown-menu.component";
import { useContext } from "react";
import { AppContext } from "context/app.provider";
import { getRequestStatusFromAction } from "utils/shared";
import { ToastStatus } from "components/toast.component";

const TableRow = styled.tr`
  div.wrapper {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      margin-right: 1rem;
      height: 50px;
      width: 50px;
      border-radius: 25px;
    }
    div.side {
      display: flex;
      flex-direction: column;
      em {
        font-style: normal;
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #1c344f;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:last-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #748595;
        }
      }
    }
  }
  td {
    em.status {
      border-radius: 60px;
      padding: 1rem;
      font-style: normal;
      width: 90px;
      text-align: center;
      &.completed {
        color: #71bf5f;
        background: #ebf6e9;
      }

      &.declined {
        color: #db6b5d;
        background: #fdf8f7;
      }
    }
  }
`;

export const RequestAction = {
  Accept: "Accept",
  Decline: "Decline",
  Complete: "Complete",
  Cancel: "Cancel",
};

export const RequestRow = ({
  id,
  name,
  phoneNo,
  type,
  quantity,
  address,
  initiationTime,
  userImage,
  currentTab,
  status,
  actions,
  productClass,
  changeRequestStatus,
  openToast,
  openComplete,
}) => {
  const { setRequests } = useContext(AppContext);

  const handleOptionSelected = async (option) => {
    if (option === RequestAction.Complete) {
      openComplete(productClass, quantity, id);
      return;
    }

    const status = getRequestStatusFromAction(option);

    const result = await changeRequestStatus({
      status,
      request_id: id,
    });

    if (result.success) {
      openToast(result.message, ToastStatus.success);

      setRequests((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return { ...item, status };
          }

          return item;
        })
      );
    }
  };

  return (
    <TableRow>
      <td>
        <div className="wrapper">
          <img src={userImage || userIcon} alt="user" />
          <div className="side">
            <em>{name}</em>
            <em>{phoneNo}</em>
          </div>
        </div>
      </td>
      <td>{type}</td>
      <td>{quantity}</td>
      <td>{address}</td>
      <td>{initiationTime.toString()}</td>
      <td>
        {currentTab === Tabs.History ? (
          <em
            className={`status ${
              status?.toLowerCase() === "completed" ? "completed" : "declined"
            }`}
          >
            {status}
          </em>
        ) : (
          <DropdownMenu options={actions} onSelected={handleOptionSelected} />
        )}
      </td>
    </TableRow>
  );
};
