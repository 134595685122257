import styled from "styled-components";
import crownIcon from "assets/svg/crown.svg";
import cancelIcon from "assets/svg/cancel.svg";
import InputComponent from "components/input.component";

const Container = styled.div`
  width: 80%;
  max-width: 650px;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    border-bottom: 1px solid #ebedf0;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      color: #1c344f;
      display: inline-block;

      img.crown {
        margin: auto 0;
      }
    }

    button {
      background: transparent;
      border: none;
      img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  form {
    padding: 2rem 2.5rem;

    label {
      display: block;
      margin-bottom: 3rem;
      span {
        font-size: 1.6rem;
        color: #1c344f;
        display: block;
        margin-bottom: 1.2rem;
      }
    }

    button {
      display: block;
      margin-left: auto;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: #55b653;
      border-radius: 8px;
      border: none;
      height: 56px;
      padding: 1.5rem;
      min-width: 105px;
    }
  }
`;

export const AccessLevel = {
  Super: "Super",
  Normal: "Normal",
};

export const PointsInfo = ({ closeOverlay, pointsInfo }) => {
  return (
    <Container>
      <header>
        <h1>
          Points Earned <img className="crown" src={crownIcon} />
        </h1>
        <button onClick={closeOverlay}>
          <img src={cancelIcon} alt="" />
        </button>
      </header>
      <form>
        <label htmlFor="pickupPoints">
          <span>Pickup Points</span>
          <InputComponent
            id="pickupPoints"
            type="text"
            disabled
            value={pointsInfo.pickupPoints}
          />
        </label>
        <label htmlFor="referralPoints">
          <span>Referral Points</span>
          <InputComponent
            id="referralPoints"
            type="text"
            disabled
            value={pointsInfo.referralPoints}
          />
        </label>
        <button onClick={closeOverlay}>Close</button>
      </form>
    </Container>
  );
};
