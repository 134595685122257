import React from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import arrowDown from "assets/svg/arrow-down.svg";
import { useRef } from "react";

const Container = styled.div`
  font-size: 1rem;
  color: #1c344f;

  @media screen and (min-width: 900px) {
    font-size: 1.4rem;
  }
`;

const SelectPageCountButton = styled.button`
  font-size: 1.2rem;
  background: none;
  border: none;
  color: #1c344f;
  margin-left: 1rem;

  @media screen and (min-width: 900px) {
    font-size: 1.6rem;
  }
`;

const PageCountOption = styled.div`
  padding: 1rem;
  cursor: pointer;
  color: #1c344f;
  font-size: 1.2rem;

  &:hover {
    background: #ccc;
  }

  @media screen and (min-width: 900px) {
    font-size: 1.6rem;
  }
`;

const SelectPageCount = ({ setItemsPerPage, selected }) => {
  const ref = useRef(null);

  const closeTooltip = () => ref.current?.close();

  const handleClick = (value) => () => {
    closeTooltip();

    setItemsPerPage(value);
  };

  return (
    <Popup
      ref={ref}
      position={"left bottom"}
      closeOnDocumentClick
      trigger={
        <SelectPageCountButton type="button">
          <span>{selected}</span> <img src={arrowDown} />
        </SelectPageCountButton>
      }
    >
      <PageCountOption onClick={handleClick(2)}>2</PageCountOption>
      <PageCountOption onClick={handleClick(10)}>10</PageCountOption>
      <PageCountOption onClick={handleClick(20)}>20</PageCountOption>
      <PageCountOption onClick={handleClick(40)}>40</PageCountOption>
    </Popup>
  );
};

const PaginationPageControllerComponent = ({
  itemsPerPage,
  setItemsPerPage,
}) => {
  return (
    <Container>
      Show rows{" "}
      <SelectPageCount
        setItemsPerPage={setItemsPerPage}
        selected={itemsPerPage}
      />
    </Container>
  );
};

export default PaginationPageControllerComponent;
