import styled from "styled-components";

import ellipseDivideIcon from "assets/svg/ellipse-divide.svg";
import { Overlay } from "./overlay.component";
import { useState } from "react";
import { RecycleRequestConfirmation } from "./overlays/recycle-request-confirmation.overlay";
import userIcon from "assets/svg/user-2.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1.8rem 2.5rem;
  border-bottom: 1px solid #dce2e6;
  & > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 1.2rem;
  }
  div.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    width: 100%;
    div.top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      h2 {
        font-weight: normal;
        font-size: 14px;
        color: #1c344f;
      }
      em {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #748595;
        &.complete,
        &.incomplete {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;

          border-radius: 60px;
          padding: 4px 10px;
        }
        &.complete {
          color: #71bf5f;
          background: #ebf6e9;
        }
        &.incomplete {
          color: #db6b5d;
          background: #fdf8f7;
        }
      }
    }
    div.bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      h2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        span {
          font-weight: 400;
          font-size: 12px;
          color: #748595;
          &:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        img {
          width: 4px;
          height: 4px;
          margin: 0 8px;
          display: inline-block;
        }
      }
      & > span {
        background: #1c344f;
        border-radius: 4px;
        display: block;
        max-width: 20px;
        width: 20%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        em {
          color: #fff;
          font-style: normal;
        }
      }
      em {
      }
    }
  }

  @media screen and (min-width: 400px) {
    div.wrapper {
      div.bottom {
        h2 {
          max-width: 220px;
        }
      }
    }
  }
`;

export const RequestCard = ({
  id,
  name,
  number,
  date,
  quantity,
  requestName,
  requestImage,
  status,
  address,
  changeRequestStatus,
  openToast,
  openComplete,
  productClass,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  return (
    <Container onClick={() => setIsOverlayOpen(true)}>
      <Overlay
        closeOverlay={() => setIsOverlayOpen(false)}
        isVisible={isOverlayOpen}
        Component={RecycleRequestConfirmation}
        extraProps={{
          name,
          number,
          date,
          quantity,
          requestImage,
          requestName,
          status,
          address,
          changeRequestStatus,
          id,
          openToast,
          openComplete,
          productClass,
        }}
      />
      <img src={requestImage || userIcon} alt="Owner of item to be recycled" />

      <div className="wrapper">
        <div className="top">
          <h2>{requestName}</h2>
        </div>

        <div className="bottom">
          <h2>
            <span>{name}</span>
            <img src={ellipseDivideIcon} alt="" />
            <span>{number}</span>
          </h2>
          <span>
            <em>{quantity}</em>
          </span>
        </div>
      </div>
    </Container>
  );
};
