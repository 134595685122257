import styled from "styled-components";

const Container = styled.div`
  padding: 4rem;
  border-radius: 12px;
  background: ${({ bgColor }) => bgColor};
  width: 100%;

  em {
    font-style: normal;
    font-size: 1.6rem;
    display: block;
    margin-bottom: 2.4rem;
    color: ${({ infoColor }) => infoColor};
  }
  h3 {
    font-size: 4.8rem;
    font-weight: 500;
    color: ${({ statColor }) => statColor};
  }

  // @media screen and (min-width: 1200px) {
  //   width: unset;
  //   padding: 4rem 3rem;
  //   max-width: 260px;
  // }

  // @media screen and (min-width: 1300px) {
  //   width: 230px;
  // }

  // @media screen and (min-width: 1500px) {
  //   width: 260px;
  // }
`;

export const Card = ({ info, stat, infoColor, statColor, bgColor }) => {
  return (
    <Container infoColor={infoColor} statColor={statColor} bgColor={bgColor}>
      <em>{info}</em>
      <h3>{stat < 10 && stat > 0 ? `0${stat}` : stat}</h3>
    </Container>
  );
};
